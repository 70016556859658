<template>
  <b-container fluid>
    <header class="bloc-title">
      <h1 class="bloc-title-content">Chargé de la visite</h1>
    </header>
    <b-row>
      <b-col sm="12" xl="6" class="siret-card-container">
        <user-card v-model="value"/>
      </b-col>
      <b-col sm="12" xl="6"></b-col>
    </b-row>
    <header class="bloc-title">
      <h2 class="bloc-title-content">Autres Visites</h2>
    </header>
    <b-row>
      <b-col>
        <visit-table :filters="filters" :fields="['date', 'siret', 'purposes', 'actions']"/>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import User from '@/views/user/model'
import UserCard from '@/views/user/card'
import VisitTable from '@/views/visit/table'

export default {
  name: 'gip.user.details',
  components: {
    UserCard, VisitTable
  },
  props: {
    value: User
  },
  mounted () {
    this.value.load()
  },
  data () {
    return {
      filters: [{
        type: '\\App\\Model\\Filter\\EqualsFilter',
        field: 'user',
        value: this.value.id
      }]
    }
  }
}
</script>
