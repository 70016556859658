<template>
  <div class="row">
    <slot name="title" class="col-2"></slot>
    <div class="col">
      <search
        :search="search"
        :default-value="defaultValue"
        @submit="onSubmit"
        :get-result-value="resultValue"
        ref="ac"
        v-if="resetator">
        <template #result="{ result, props }">
          <li v-bind="props">
            {{ result.postcode.code }} {{ result.name }}
          </li>
        </template>
      </search>
    </div>
    <slot name="action" class="col-2"></slot>
  </div>
</template>
<script>
import Postcode from './model'
import Selector from '@/views/selector'
import curry from 'ramda/src/curry'
const re = /^\d{1,5}$/
const soundLikeACode = re.test.bind(re)
const like = curry(function (field, value) {
  return {
    type: '\\App\\Model\\Filter\\LikeFilter',
    field,
    value: JSON.stringify(`%${value}%`)
  }
})

export default {
  name: 'gip.city.postcode.select',
  extends: Selector,
  props: {
    value: Postcode,
    field: {
      default: () => 'name'
    },
    type: {
      default: () => Postcode
    }
  },
  data () {
    return {
      serializers: ['short']
    }
  },
  methods: {
    baseFilters (val) {
      const tokensNumber = val.trim().split(' ').filter(s => s.length).filter(soundLikeACode)
      let filters = tokensNumber.map(like('postcode.code'))

      const tokensString = val.trim().split(' ').filter(s => s.length).filter(t => !soundLikeACode(t))
      filters = filters.concat(tokensString.map(like('name')))
      if (filters.length >= 1) {
        return [{
          type: '\\App\\Model\\Filter\\AndFilter',
          filters
        }]
      }
      return []
    }
  },
  computed: {
    defaultValue () {
      if (this.value && this.value.postcode && this.value.postcode.code && this.value.name) {
        return `${this.value.postcode.code} ${this.value.name}`
      }
      return null
    }
  }
}
</script>
