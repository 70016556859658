<template>
  <b-container fluid>
    <header class="bloc-title">
      <h1 class="bloc-title-content">Fiche de la visite</h1>
      <b-button-toolbar v-if="value.canEdit($user)">
        <!-- <b-button :to=" { name: 'visit_duplicate', params: { id } }" variant="primary">
          <unicon name="copy"></unicon>
        </b-button> -->
        <b-button :to=" { name: 'visit_edit', params: { id } }" variant="primary">
          <unicon name="pen"></unicon>
        </b-button>
      </b-button-toolbar>
    </header>
    <b-row>
      <b-col cols="12" xl="4" class="card-container">
        <visit-card v-model="value"/>
      </b-col>
      <b-col cols="12" xl="8">

        <header class="bloc-title">
          <h2 class="bloc-title-content">
            Métier(s) ciblé(s) lors des échanges avec l'entreprise
            <tips title="Cliquer sur + pour ajouter un nouveau métier"/>
          </h2>
          <b-button-toolbar v-if="value.canEdit($user)">
            <b-button :to="{ name: 'visit_trade_add' }" variant="primary">
              <unicon name="plus"/>
            </b-button>
          </b-button-toolbar>
        </header>
        <visit-trade-table :visitTrades="trades" :visit="value" :busy="state.loading"/>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import Visit from '@/views/visit/model'
import model from '@/views/model.mixin'
import format from 'date-fns/format'
import download from '@/views/download'
import VisitTradeTable from '@/views/visit_trade/table'
import VisitCard from '@/views/visit/card_full'
import Tips from '@/views/tips'

export default {
  name: 'gip.visit.details',
  props: {
    value: Visit
  },
  data () {
    return {
      skillFields: [
        { key: 'skill.name', label: 'Compétences' },
        { key: 'utility' },
        { key: 'numerical' },
        { key: 'ecological' },
        { key: 'technological' }
      ]
    }
  },
  components: { VisitCard, VisitTradeTable, Tips },
  mounted () {
    this.value.load()
  },
  mixins: [model('value')],
  methods: {
    formatDate (date) {
      return format(new Date(date), 'dd/MM/yyyy')
    },
    async removeTrade (trade) {
      try {
        await trade.delete()
        this.$notify({
          content: `${trade.trade.name} a été retiré`,
          title: 'Visite'
        })
        this.trades.splice(this.trades.indexOf(trade), 1)
      } catch {
        this.$notify({
          content: `${trade.trade.name} n'a pu être retiré`,
          title: 'Visite'
        })
      }
    },
    onDowload (file) {
      file
        .getBlob()
        .then(blob => download(blob, file.name))
    }
  }
}
</script>
