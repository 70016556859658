export default function (user) {
  return function (to, from, next) {
    if (['user_agreement', 'content_details'].includes(to.name) || user.needAgreements.length === 0) {
      next()
    } else {
      return next({
        name: 'user_agreement'
      })
    }
  }
}
