import axios from 'axios'
export default function search (data, config, cancelToken) {
  if (!config.api || config.api === 'skapane') {
    return axios.get(config.url, {
      params: Object.assign({
        type_text: 'skill',
        n: config.limit || 5,
        type_associated_text: 'emploi'
      }, data),
      cancelToken
    }).then(({ data }) => data)
  }
  return axios.post(config.url, {
    query: data.text,
    model: config.model || 'normalized_skills_150k',
    limit: config.limit || 5,
    ibcOnly: true
  }, { cancelToken, headers: { 'X-Auth-Token': config.token } }).then(({ data }) => data.map(skill => {
    return {
      text_similar: skill.ibc_label
    }
  }))
}
