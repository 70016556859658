<template>
  <b-container fluid>
    <header class="bloc-title">
      <h1 class="bloc-title-content">Fiche de l'entreprise</h1>
    </header>
    <b-row>
      <b-col sm="12" xl="6" class="card-container">
        <siret-card v-model="value" :link="false" />
      </b-col>
      <b-col sm="12" xl="6"></b-col>
    </b-row>
    <header class="bloc-title">
      <h2 class="bloc-title-content">Visites</h2>
      <b-button-toolbar>
        <b-button :to="'/visit/entreprise/'+value.id" variant="primary">
          <unicon name="plus"></unicon>
        </b-button>
      </b-button-toolbar>
    </header>
    <b-row>
      <b-col>
        <visit-table :filters="filters" :fields="['date', 'user', 'purposes', 'trade', 'actions']" :limit="visitLimit" :page="visitPage">
          <template v-slot:user="user">
            <b-link :to="{ name: 'user_details', params: { id: user.id} }">{{ user.firstname }} {{ user.lastname }} - <i>{{ user.organisations.map(o => o.name).join(', ') }}</i></b-link>
          </template>
        </visit-table>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import Siret from '@/views/siret/model'
import SiretCard from './card'
import VisitTable from '@/views/visit/table'

export default {
  name: 'gip.siret.details',
  components: {
    SiretCard, VisitTable
  },
  props: {
    visitPage: Number,
    visitLimit: Number,
    value: Siret
  },
  mounted () {
    this.value.load()
  },
  data () {
    return {
      filters: [{
        type: '\\App\\Model\\Filter\\EqualsFilter',
        field: 'siret',
        value: this.value.id
      }]
    }
  }
}
</script>
