<template>
  <b-container fluid>
    <b-row>
      <b-col id="notifications_table" v-on:scroll.passive="onScroll">
        <b-spinner v-if="loading"/>
        <template v-else>
          <template v-if="notifications.length > 0">
            <b-table responsive striped :items="notifications" :fields="fields">
              <template v-slot:cell(createdAt)="row">
                {{ createdDate(row.item.createdAt) }}<br>{{ createdHour(row.item.createdAt) }}
              </template>
              <template v-slot:cell(message)="row">
                {{ row.item.title }}<br><span v-html="row.item.message"></span>
              </template>
              <template v-slot:cell(actions)="row">
                <template v-if="hasFile(row.item) !== -1">
                  <b-button-group>
                    <b-button @click="onDowload(row.item)" variant="primary">
                      <unicon name="file-download"/>
                    </b-button>
                    <b-button @click="removeNotif(row.item)" variant="primary">
                      <unicon name="trash-alt"/>
                    </b-button>
                  </b-button-group>
                </template>
              </template>
            </b-table>
            <b-spinner v-if="loadingMore"/>
          </template>
          <p id="no-notif" v-else>Aucune notification à afficher.</p>
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import format from 'date-fns/format'
import Attachment from '@/views/attachment/model'
import Notification from './model'
import download from '@/views/download'

export default {
  name: 'gip.notification.list',
  props: {
    open: Boolean
  },
  data () {
    return {
      loading: false,
      loadingMore: false,
      limit: 10,
      offset: 1,
      notifications: [],
      fields: [
        { key: 'createdAt', label: '' },
        { key: 'message', label: '' },
        { key: 'actions', label: '' }
      ]
    }
  },
  watch: {
    open: function (val) {
      if (val === true) {
        const newNotif = this.notifications.filter(notification => {
          return notification.archived === false
        })
        newNotif.map(n => {
          n.archived = true
          n.save({
            without: ['createdAt', 'message', 'title']
          })
        })
        this.$emit('new', false)
      }
    }
  },
  async mounted () {
    this.loadNotifications()
    setInterval(() => {
      this.loadNotifications()
    }, 3000)
  },
  methods: {
    async removeNotif (notif) {
      try {
        await notif.delete()
        this.$notify({
          content: `Notification retirée`,
          title: 'Notifications'
        })
        this.notifications.splice(this.notifications.indexOf(notif), 1)
      } catch (err) {
        this.$notify({
          content: `La notification n'a pu être retirée`,
          title: 'Notifications'
        })
      }
    },
    createdDate (date) {
      return format(new Date(date), 'dd/MM/yyyy')
    },
    createdHour (date) {
      return format(new Date(date), 'HH:mm')
    },
    hasFile (notification) {
      return notification.message.indexOf('data-file')
    },
    async loadNotifications () {
      if (!this.open && this.$user.isAuth) {
        try {
          this.loading = Notification.search({
            limit: this.limit,
            orders: [{
              column: 'createdAt',
              direction: 'DESC'
            }]
          })
          const { results, count } = await this.loading
          this.count = count
          this.notifications.splice(0, this.notifications.length, ...results)
          const newNotif = this.notifications.filter(notification => {
            return notification.archived === false
          })
          this.$emit('new', newNotif.length > 0)
        } catch (err) {
          this.handleError(err)
        }
        this.loading = false
      }
    },
    async loadMoreNotifications () {
      if (this.$user.isAuth) {
        try {
          this.loadingMore = Notification.search({
            page: this.offset,
            limit: this.limit,
            orders: [{
              column: 'createdAt',
              direction: 'DESC'
            }]
          })
          const { results, count } = await this.loadingMore
          this.count = count
          this.notifications.push(...results)
        } catch (err) {
          this.handleError(err)
        }
        this.loadingMore = false
      }
    },
    async onDowload (notification) {
      const regex = new RegExp('[\\s\\r\\t\\n]*([a-z0-9\\-_]+)[\\s\\r\\t\\n]*=[\\s\\r\\t\\n]*([\'"])((?:\\\\\\2|(?!\\2).)*)\\2', 'ig')
      let attributes = {}
      let match = null
      while ((match = regex.exec(notification.message))) {
        attributes[match[1]] = match[3]
      }
      let file = new Attachment({ id: attributes['data-file'] })
      file
        .getBlobWithName()
        .then(response => {
          download(response.blob, response.fileName)
        })
    },
    onScroll (el) {
      if ((el.srcElement.offsetHeight + el.srcElement.scrollTop) >= el.srcElement.scrollHeight) {
        this.offset++
        this.loadMoreNotifications()
      }
    }
  }
}
</script>
