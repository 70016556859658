import Model from '@/views/model'
import Region from '@/views/region/model'

class Department extends Model {
  constructor (data = {}) {
    super(data)
  }
  static search (options) {
    return super.search(options, Department)
  }
}

Department.prototype.schema = {
  collections: {},
  includes: {
    region: Region
  }
}
Department.prototype.properties = Model.prototype.properties.concat(['code', 'name', 'lat', 'lng', 'region'])
Department.prototype.baseUrl = '/crud/department'

export default Department
