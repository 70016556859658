<template>
  <b-form @submit.prevent="onSubmit">
    <h1>Mot de passe</h1>
    <b-alert show>Votre mot de passe doit comporter au moins une majuscule, un caractère spécial, un chiffre et être d'une longueur minimum de 8 caractères.</b-alert>
    <b-form-group label="Nouveau mot de passe*">
      <b-input-group>
        <b-input v-model="password" :type="inputType" required/>
        <b-input-group-append>
          <b-button @click="switchInputType" variant="primary">
            <unicon name="eye" v-if="inputType === 'password'"></unicon>
            <unicon name="eye-slash" v-else></unicon>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-form-group label="Confirmez le nouveau mot de passe*">
      <b-input-group>
        <b-input v-model="confirm" :type="inputConfirmType" required/>
        <b-input-group-append>
          <b-button @click="switchInputConfirmType" variant="primary">
            <unicon name="eye" v-if="inputConfirmType === 'password'"></unicon>
            <unicon name="eye-slash" v-else></unicon>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-button type="submit" variant="primary" :disabled="!isValid">Enregistrer</b-button>
  </b-form>
</template>
<script>
import User from './model'
import Error from '@/views/error/mixin'

export default {
  name: 'gip.user.credential',
  mixins: [Error],
  props: {
    value: {
      type: User,
      default: function () {
        return this.$user
      }
    }
  },
  data () {
    return {
      password: '',
      confirm: '',
      inputType: 'password',
      inputConfirmType: 'password'
    }
  },
  methods: {
    async onSubmit () {
      if (this.isValid) {
        await this.value.me()
        this.value
          .credential(this.password)
          .then(
            () => this.$router.push({ name: 'home' }),
            this.handleError.bind(this)
          )
      }
    },
    switchInputType () {
      if (this.inputType === 'password') {
        this.inputType = 'text'
      } else {
        this.inputType = 'password'
      }
    },
    switchInputConfirmType () {
      if (this.inputConfirmType === 'password') {
        this.inputConfirmType = 'text'
      } else {
        this.inputConfirmType = 'password'
      }
    }
  },
  computed: {
    isValid () {
      return this.password.length >= 8 &&
        /[A-Z]/.test(this.password) &&
        /[0-9]/.test(this.password) &&
        /[^\w]/.test(this.password) &&
        this.password === this.confirm
    }
  }
}
</script>
<style>
.form-group button {
  margin-right: 5px;
}
</style>
