import { EventEmitter } from 'eventemitter3'
import omit from 'ramda/src/omit'
import debug from 'debug'
import pick from 'ramda/src/pick'
const log = debug('app:model')

class Model extends EventEmitter {
  constructor (data = {}) {
    super()
    this.schema = this.schema || { collections: {}, includes: {} }
    this.modelState = {}
    this.setState('saving', false)
    this.setState('loading', false)
    Object.assign(
      this,
      ...Object.entries(this.schema.collections).map(([name]) => ({ [name]: [] })),
      ...Object.entries(this.schema.includes).map(([name, M]) => ({ [name]: new M() }))
    )
    this.setData(Object.assign({}, data))
    this.setState('valid', this.isValid(this))
  }
  setData (data) {
    if (data !== null && typeof data !== 'undefined') {
      const without = []
      Object.entries(this.schema.collections).forEach(([name, M]) => {
        if (name in data) {
          without.push(name)
          this[name].splice(0, this[name].length, ...data[name].map(t => t instanceof M ? t : new M(t)))
        }
      })
      Object.entries(this.schema.includes).forEach(([name, M]) => {
        if (name in data) {
          without.push(name)
          this[name] = data[name] instanceof M ? data[name] : new M(data[name])
        }
      })
      Object.assign(this, omit(without, data))
      this.setState('valid', this.isValid(this))
      this.emit('update', this)
    }
    return this
  }
  setState (state, value) {
    const old = this.modelState[state]
    if (old !== value) {
      this.modelState[state] = value
      this.emit('state', state, value)
    }
  }
  async save (props = { without: [], with: [], childs: {} }) {
    props = ensureProps(props)
    props.without.push('id')
    let response = null
    try {
      if (this.id) {
        // Model.api.then(api => api.patch([this.baseUrl, this.id].join('/') + '?_info=on', this.marshall(props)))
        response = Model.api.then(api => api.patch([this.baseUrl, this.id].join('/'), this.marshall(props)))
      } else {
        // Model.api.then(api => api.put(this.baseUrl + '?_info=on', this.marshall(props)))
        response = Model.api.then(api => api.put(this.baseUrl, this.marshall(props)))
      }
      this.setState('saving', response)
      let done = await response
      this.setData(done.data)
      this.setState('saving', false)
    } catch (err) {
      this.setState('saving', false)
      throw response
    }
    return Promise.resolve(this)
  }
  async load () {
    if (this.id) {
      let response
      try {
        response = Model.api.then(api => api.get([this.baseUrl, this.id].join('/')))
        this.setState('loading', response)
        const { data } = await response
        this.setData(data)
        this.setState('loading', false)
        return this
      } catch (err) {
        log(err)
        this.setState('loading', false)
        throw response
      }
    }
  }
  async delete () {
    if (this.id) {
      try {
        this.setState('loading', true)
        await Model.api.then(api => api.delete([this.baseUrl, this.id].join('/')))
        this.setState('loading', false)
        return this
      } catch (err) {
        log(err)
        this.setState('loading', false)
        throw err
      }
    }
  }
  marshall (props, depth = false) {
    props = ensureProps(props)
    return this.properties.reduce((acc, k) => {
      if (typeof props[k] !== 'undefined' && props[k].fn) {
        acc[k] = props[k].fn(this[k])
        return acc
      }
      if ((props.with.length === 0 || props.with.includes(k)) && !props.without.includes(k)) {
        if (typeof this[k] !== 'undefined') {
          if (this[k] !== null && Array.isArray(this[k]) && !depth) {
            if (props.childs[k] && props.childs[k].fn) {
              acc[k] = this[k].map(props.childs[k].fn)
            } else {
              acc[k] = this[k].map(item => item && typeof item.marshall === 'function' ? item.marshall(props.childs[k]) : item)
            }
          } else if (this[k] !== null && typeof this[k] === 'object' && this[k].marshall && !depth) {
            acc[k] = this[k].marshall(props.childs[k])
          } else {
            acc[k] = this[k]
          }
        } else {
          acc[k] = null
        }
      }
      return acc
    }, {})
  }
  canEdit (user) {
    return user.roles.includes('ROLE_ADMIN') || this.isOwner(user)
  }
  isOwner (user) {
    return this.user && user && this.user.id === user.id
  }
  static async search (options = {}, ToModel) {
    const limit = 'limit' in options ? options.limit : 10
    const page = options.page || 1
    const filters = options.filters || []
    const orders = options.orders || []
    const serializers = options.serializers || []
    const cancelToken = options.cancelToken
    const headers = {}
    if (serializers.length > 0) {
      headers['x-serializer-groups'] = serializers.join(',')
    }
    const { data } = await Model.api.then(api => api.post(ToModel.prototype.baseUrl, { limit, offset: (page - 1) * limit, filters, orders }, { headers, cancelToken }))
    data.results = data.results.map(item => new ToModel(item))
    return Promise.resolve(data)
  }
  isValid (value, schema) {
    schema = schema || this.validator
    if (schema) {
      let valid = schema.validate(pick(this.properties, value))
      log(valid.error || 'valid')
      return !valid.error
    }
    return true
  }
}

function ensureProps (props = {}) {
  return Object.assign({ without: [], with: [], childs: {} }, props)
}

Model.prototype.properties = ['id']
Model.prototype.baseUrl = ''

export default Model
