<template>
  <b-card>
    <avatar v-model="avatar" width="50px" :gender="gender" />
    <div>
      <h4 class="card-title">{{ firstname }} {{ lastname }}</h4>
      <h6 class="card-subtitle text-muted mb-2">{{ organisations.map(o => o.name).join(', ') }}</h6>
    </div>
    <b-row>
      <b-col>
        <ul>
          <li>{{ phone }}</li>
          <li>{{ email }}</li>
          <li>{{ address }}</li>
        </ul>
      </b-col>
    </b-row>
    <slot name="action" :user="value"></slot>
  </b-card>
</template>
<script>
import User from '@/views/user/model'
import model from '@/views/model.mixin'
import Avatar from '@/views/attachment/avatar.vue'

export default {
  name: 'gip.user.card',
  components: { Avatar },
  props: {
    value: {
      type: User,
      default () {
        return this.$user
      }
    }
  },
  mixins: [model('value')]
}
</script>
<style scoped>
.user-picture {
  float: left;
}
.card-title, .card-subtitle {
  padding-left: 60px;
}
</style>
