export default function (user) {
  return function (to, from, next) {
    if (to.meta.requireRole) {
      if (user && user.isAuth && user.roles && user.roles.includes(to.meta.requireRole)) {
        next()
      } else {
        return next({
          name: 'login',
          query: { redirect: to.fullPath }
        })
      }
    } else {
      next()
    }
  }
}
