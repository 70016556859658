<template>
  <div :class="{ 'rounded-circle': circle, 'avatar-image': true,  'user-picture': true, 'male': gender === 'male'}" :style="imageStyle"></div>
</template>
<script>
import Avatar from '@/views/attachment/avatar'
import model from '@/views/model.mixin'

export default {
  name: 'gip.avatar',
  props: {
    value: Avatar,
    gender: String,
    width: String,
    circle: {
      type: Boolean,
      default: true
    }
  },
  mixins: [model('value')],
  computed: {
    imageStyle () {
      const s = {
        width: this.width,
        height: this.width
      }
      if (this.preview) {
        s.backgroundImage = `url("${this.preview}")`
      }
      return s
    }
  }
}
</script>
<style>
.avatar-container {
}
.avatar-image {
  display: inline-block;
  background-image: url('../../assets/female.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.avatar-image.male {
  background-image: url('../../assets/male.png');
}
</style>
