import Model from '@/views/model'
import debug from 'debug'
const log = debug('app:model')

class Content extends Model {
  static search (options) {
    return super.search(options, Content)
  }

  async load () {
    if (this.id) {
      try {
        this.setState('loading', true)
        const { data } = await Model.api.then(api => api.get([this.baseUrl, this.id].join('/')))
        this.setData(data)
        this.setState('loading', false)
        return this
      } catch (err) {
        log(err)
        this.setState('loading', false)
        throw err
      }
    } else if (this.name) {
      try {
        this.setState('loading', true)
        const { data } = await Model.api.then(api => api.get(['/content', this.name].join('/')))
        this.setData(data)
        this.setState('loading', false)
        return this
      } catch (err) {
        log(err)
        this.setState('loading', false)
        throw err
      }
    }
  }
}

Content.prototype.properties = Model.prototype.properties.concat(['name', 'title', 'template'])
Content.prototype.baseUrl = '/crud/content'

export default Content
