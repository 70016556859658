<script>
import Deadline from './model'
import SelectorAll from '@/views/selectorAll'

export default {
  name: 'gip.selector.deadline',
  extends: SelectorAll,
  props: {
    value: Deadline,
    field: {
      default: () => 'label'
    },
    type: {
      default: () => Deadline
    }
  }
}
</script>
