<template>
  <div style="text-justify: center;">
    <b-form class="justify-content-between">
      <b-row>
        <b-col cols="6">
          <b-form-group label="Softskill*">
            <soft-skill-select v-model="softSkill" ref="softskill" @input="select" @text="event => $emit('text', event)" :deny="deny"/>
          </b-form-group>
        </b-col>
        <b-col cols="6" sm="4" class="score-actions">
          <b-form-group label="Score*" :class="scoreClasses">
            <galaxy v-model="utility" writable @change="$emit('change', value)" class="text-center"/>
          </b-form-group>
          <slot name="actions"></slot>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12"  xl="3" class="mr-0">
          <header>Filtrer par catégories</header>
          <category-select v-model="categories" :categories="categoriesList"/>
        </b-col>
        <b-col cols="12" xl="9" class="select-softskills">
          <div v-if="categories.length">Sélectionnez un softskill et ajoutez lui un score</div>
          <list-softskill :categories="categories" @select="select" :deny="deny"/>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<style scoped lang="scss">
  .categories {
    margin: 1em 0;
  }
  .gip-shake {
    animation-name: shake;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  .score-actions {
    display: flex;
    flex-direction: row;
    fieldset {
      min-width: auto;
    }
    button {
      margin-top: 2em;
      margin-left: 2em;
    }
  }
  @media(max-width: 800px) {
    .score-actions {
      flex-direction: column;
      fieldset {
        margin-right: 1em;
      }
    }
    .select-softskills {
      margin-top: 1em;
    }
  }
  @keyframes shake {
    0%, 100% {
      transform: translateX(0);
    }
    10%, 30%, 50%, 70%, 90% {
     transform: translateY(-5px);
    }
    20%, 40%, 60%, 80% {
      transform: translateY(5px);
    }
  }
</style>
<script>
import VisitTradeSoftskill from '@/views/visit_trade_softskill/model'
import SoftSkillSelect from '@/views/softskill/select'
import utilities from '@/views/utility/cache'
import Galaxy from '@/views/utility/galaxy'
import model from '@/views/model.mixin'
import Softskill from '@/views/softskill/model'
import pick from 'ramda/src/pick'
import Category from '@/views/softskill_category/model'
import CategorySelect from '@/views/softskill_category/select'
import ListSoftskill from '@/views/softskill_category/list_softskill'

export default {
  name: 'gip.visit.trade.softskill.form',
  props: {
    value: VisitTradeSoftskill,
    deny: {
      type: Array,
      default () {
        return []
      }
    }
  },
  components: { SoftSkillSelect, Galaxy, CategorySelect, ListSoftskill },
  mixins: [model('value')],
  data () {
    Category.search({ limit: 100 })
      .then(({ results }) => {
        this.categoriesList.splice(
          0,
          this.categoriesList.length,
          ...Category.deduplicate(results)
        )
      })
    return {
      categoriesList: [],
      categories: [],
      scoreClasses: ''
    }
  },
  methods: {
    reset () {
      this.softSkill.removeListener()
      this.utility.removeListener()
      this.softSkill = new Softskill()
      this.value.setData({ utility: utilities.list[0].clone() })
      this.$refs.softskill.reset()
    },
    select (softSkill) {
      this.value.setData({ softSkill })
      this.$refs.softskill.$el.scrollIntoView()
      this.bumpScore()
      this.$emit('change', this.value)
    },
    onSubmit () {
      this.value.setData(pick(this.value.properties, this))
    },
    bumpScore () {
      this.scoreClasses = 'gip-shake'
      setTimeout(() => {
        this.scoreClasses = ''
      }, 1000)
    }
  }
}
</script>
