import Model from '@/views/model'
import { required, checkValue, checkPattern } from '@/views/validation/validator'

class Contact extends Model {
  static search (options) {
    return super.search(options, Contact)
  }
}

Contact.prototype.properties = Model.prototype.properties.concat(['civility', 'lastname', 'firstname', 'function', 'phone', 'email', 'employee'])
Contact.prototype.validator = {
  validate (value) {
    const requiredFields = [
    ]
    let message = null
    message = required(requiredFields, value)
    if (!message) {
      if (value.civility) {
        const civilityValues = ['male', 'female', null]
        message = checkValue(civilityValues, value.civility)
      }
      if (!message) {
        if (value.phone) {
          message = checkPattern(value.phone, true, /^[0-9]+$/, 9)
        }
        if (!message && value.email) {
          message = checkPattern(value.email, true, /\S+@\S+\.\S+/)
        }
      }
    }
    if (message) {
      return { error: message }
    }
    return 'valid'
  }
}

Contact.prototype.baseUrl = '/crud/contact'
export default Contact
