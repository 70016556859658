import Model from '@/views/model'
import format from 'date-fns/format'

class Attachment extends Model {
  constructor (data) {
    super(data)
    this.setState('progress', 0)
  }
  save () {
    let fd = new FormData()
    fd.append('file[file]', this.file)
    fd.append('file[name]', this.name)
    const config = {
      onUploadProgress: event => {
        this.setState('progress', Math.round((event.loaded * 100) / event.total))
      }
    }
    return Model.api.then(api => {
      return api.post(
        ['file', 'upload', this.type].join('/'),
        fd,
        config
      ).then(({ data }) => {
        return this.setData(data)
      })
    })
  }
  getBlob () {
    if (this.file) {
      return Promise.resolve(this.file)
    }
    return Model.api.then(api => {
      return api.get(
        ['file', 'upload', this.id, 'serve'].join('/'),
        { responseType: 'blob' }
      ).then(({ data }) => {
        this.file = data
        return this.file
      })
    })
  }
  getBlobWithName () {
    if (this.file) {
      return Promise.resolve(this.file)
    }
    return Model.api.then(api => {
      return api.get(
        ['file', 'upload', this.id, 'serve'].join('/'),
        { responseType: 'blob' }
      ).then(response => {
        let filename = 'ibc_' + format(new Date(), 'yyyyMMdd')
        if (response.headers['content-disposition']) {
          const contentDisposition = response.headers['content-disposition'].split('filename=')
          if (contentDisposition.length > 1) {
            filename = contentDisposition.pop()
          }
        }
        this.file = response.data
        return {
          'blob': this.file,
          'fileName': filename
        }
      })
    })
  }
  getB64 () {
    return Model.api.then(api => {
      return api.get(
        ['file', 'upload', this.id, 'base64'].join('/')
      ).then(({ data }) => this.setData({ preview: `data:${this.mime.name};base64,${data}` }))
    })
  }
  async blobToB64 () {
    await this.getBlob()
    let reader = new FileReader()
    reader.addEventListener('load', () => this.setData({ preview: reader.result }), false)
    if (this.file) {
      reader.readAsDataURL(this.file)
    }
  }
}
Attachment.prototype.type = 'attachment'
Attachment.prototype.properties = Model.prototype.properties.concat(['name', 'file', 'preview'])
Attachment.prototype.baseUrl = '/file/upload'

export default Attachment
