import Model from '@/views/model'

const schema = {
  collections: {},
  includes: {}
}

class VisitPurpose extends Model {
  static search (options) {
    return super.search(options, VisitPurpose)
  }
}

VisitPurpose.prototype.properties = Model.prototype.properties.concat(['label'])
VisitPurpose.prototype.baseUrl = '/crud/visit_purpose'

VisitPurpose.prototype.schema = schema

export default VisitPurpose
