<template>
  <b-container>
    <b-jumbotron lead="Cette page n'existe pas" border-variant="warning">
      <template #header >
        <header class="bloc-title">
          <h1 class="bloc-title-content">404</h1>
        </header>
      </template>
    </b-jumbotron>
  </b-container>
</template>
<script>
export default {
  name: 'gip.error.404'
}
</script>
