<template>
  <section>

    <header class="bloc-title">
      <h1 class="bloc-title-content">Langues</h1>
    </header>

    <visit-trade-language-form v-model="language" ref="language" @change="() => isAddLanguageUnable = canAddLanguage()" class="mt-5">
      <template v-slot:actions>
        <div>
          <b-button variant="primary" @click="addLanguage" :disabled="!isAddLanguageUnable" title="Ajouter la langue">
            <unicon name="plus"/>
          </b-button>
        </div>
      </template>
    </visit-trade-language-form>

    <language-table :languages="languages" :columns="languagesFields" :busy="state.loading">
      <template v-slot:utility="{language}">
        <galaxy v-model="language.utility" writable />
      </template>
      <template v-slot:table-busy>
        <div class="text-center">
          <b-spinner variant="primary"/>
        </div>
      </template>
    </language-table>

  </section>
</template>
<script>
import VisitTrade from '@/views/visit_trade/model'
import LanguageTable from '@/views/visit_trade_language/table'
import VisitTradeLanguage from '@/views/visit_trade_language/model'
import model from '@/views/model.mixin'
import VisitTradeLanguageForm from '@/views/visit_trade_language/form'
import Galaxy from '@/views/utility/galaxy'

export default {
  name: 'gip.visit.trade.languages.form',
  props: {
    value: VisitTrade
  },
  components: { LanguageTable, Galaxy, VisitTradeLanguageForm },
  mixins: [model('value')],
  data () {
    const language = new VisitTradeLanguage({ utility: { score: 0 } })
    language.language.on('update', () => {
      this.isAddLanguageUnable = this.canAddLanguage()
    })
    return {
      languagesFields: [
        { key: 'language.name', label: 'Langue' },
        { key: 'utility' }
      ],
      language,
      isAddLanguageUnable: false
    }
  },
  methods: {
    addLanguage () {
      if (this.canAddLanguage()) {
        this.languages.push(new VisitTradeLanguage(this.language.marshall()))
        const language = new VisitTradeLanguage({ utility: { score: 0 } })
        language.language.on('update', () => {
          this.isAddLanguageUnable = this.canAddLanguage()
        })
        this.language = language
        this.isAddLanguageUnable = this.canAddLanguage()
        this.$refs.language.reset()
      }
    },
    canAddLanguage () {
      return typeof this.language.language.id === 'number' &&
        this.language.utility.score > 0 &&
        !this.languages.some(s => s.language.id === this.language.language.id)
    },
    save () {
      const args = {
        with: ['languages'],
        languages: {
          fn: all => all
            .filter(s => Number(s.utility.score) > 0)
            .map(s => {
              const args = {
                utility: { fn: u => u.id },
                language: { fn: s => s.id }
              }
              if ('id' in s && s.id !== null) {
                args.with = ['id']
              } else {
                args.without = ['id']
              }
              return s.marshall(args)
            })
        }
      }
      this.value
        .save(args)
        .then(() => {
          this.$notify({
            content: `langues de ${this.value.trade.name} modifiées`,
            title: 'Langues métier'
          })
          this.$emit('done', this.value)
        }).catch(error => {
          this.handleError(error)
        })
    }
  }
}
</script>
<style lang="scss">
  @import '@/assets/custom-vars.scss';
  .visit-trade-skills_title {
    max-width: 50%;
    margin: 0 auto;
    .card-body {
      display: flex;
      align-items: center;
    }
    .unicon {
      margin: 0 1em;
    }
    .visit-trade-skills_media {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
    }
    h3 {
      display: inline-block;
      background: #ffffff77;
      border-radius: 10px;
      padding: 0.2em 0.4em;
      margin: 0;
    }
  }
  @media screen and (max-width: 1200px) {
    .visit-trade-skills_title {
      max-width: 100%;
    }
  }
</style>
