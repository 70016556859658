<template>
  <div class="row">
    <slot name="title" class="col-2"></slot>
    <div class="col">
      <search
        :search="search"
        :default-value="defaultValue"
        @submit="onSubmit"
        @keydown.enter.prevent="() => null"
        :get-result-value="resultValue"
        ref="ac"
        v-if="resetator">
        <template #result="{ result, props }">
          <slot name="result" :result="result" :props="props"/>
        </template>
      </search>
    </div>
    <slot name="action" class="col-2"></slot>
  </div>
</template>
<script>
import Search from '@trevoreyre/autocomplete-vue'
import Model from '@/views/model'
import Error from '@/views/error/mixin'

export default {
  name: 'gip.selector',
  components: { Search },
  props: {
    value: Model,
    field: {
      type: String,
      default: () => 'name'
    },
    required: Boolean,
    minLength: {
      type: Number,
      default: 3
    }
  },
  mixins: [Error],
  data () {
    this.value.on('update', () => {
      this.refresh()
    })
    return {
      resetator: true,
      lastInput: '',
      filters: []
    }
  },
  watch: {
    value (val, old) {
      this.refresh()
    },
    lastInput (next, previous) {
      this.$emit('text', next)
    }
  },
  methods: {
    async search (val) {
      if (val.length >= this.minLength) {
        this.lastInput = val
        try {
          // @todo throttle and cancel previous query
          this.loading = this.type.search({
            filters: this.baseFilters(val, this.field)
              .concat(this.filters),
            serializers: this.serializers || ['default']
          })
          const { results } = await this.loading
          return results
        } catch (err) {
          this.handleError(err)
        }
      }
      this.loading = false
      return []
    },
    onSubmit (result) {
      this.lastInput = result.name
      this.$emit('input', result)
    },
    resultValue (result) {
      return result[this.field]
    },
    reset () {
      this.lastInput = ''
      if (this.$refs.ac) {
        this.$refs.ac.core.hideResults()
        this.$refs.ac.core.setValue()
      }
    },
    baseFilters (val, field) {
      return [{
        type: '\\App\\Model\\Filter\\AndFilter',
        filters: val.trim().split(' ').map(val => ({
          type: '\\App\\Model\\Filter\\LikeFilter',
          field,
          value: JSON.stringify(`%${val}%`)
        }))
      }]
    },
    refresh () {
      // force aucomplete to take value change into account
      this.resetator = false
      this.lastInput = this.value[this.field] || ''
      this.$nextTick(() => {
        this.resetator = true
      })
    }
  },
  computed: {
    defaultValue () {
      if (this.value && this.resetator) {
        return this.value[this.field]
      }
      return null
    }
  }
}
</script>
