<template>
<b-spinner v-if="state.loading" variant="primary"/>
<b-form v-else @submit.prevent="onSubmit">
  <header class="bloc-title">
    <h4 class="bloc-title-content">Visite</h4>
  </header>
  <b-row v-if="id">
    <b-col>
      <p><span class="bold">ID : </span>{{ id }}</p>
    </b-col>
  </b-row>
  <b-row>
    <b-col cols="12" xl="6">
      <p><span class="bold">Chargé.e de la visite : </span>{{ user.firstname }} {{ user.lastname }}</p>
    </b-col>
    <b-col cols="12" xl="6">
      <p><span class="bold">Type de structure : </span>{{ user.organisations.map(o => o.name).join(', ') }}</p>
    </b-col>
  </b-row>
  <b-row>
    <b-col cols="12" xl="6">
      <b-form-group label="Entreprise*">
        <siret-select v-model="siret" class="mb-3">
          <template #result="{ result, props }">
            <li v-bind="props">
              {{ result.name }} <i class="text-secondary small ml-2">{{ result.cityPostcode.name }}</i>
            </li>
          </template>
        </siret-select>
      </b-form-group>
    </b-col>
    <b-col cols="12" xl="6">
      <b-form-group label="Date de visite de l'entreprise">
        <datepicker :monday-first="true" :highlighted="{ customPredictor: d => d > new Date() }" :language="fr" :bootstrap-styling="true" v-model="date" format="dd/MM/yyyy"></datepicker>
      </b-form-group>
    </b-col>
  </b-row>
  <b-row>
    <b-col cols="12" xl="6">
      <b-form-group>
        <template v-slot:label>
          Objets de la visite*
          <tips title="Vous pouvez ajouter plusieurs objets de visite"/>
        </template>
        <purpose-select v-model="purpose" @input="addPurpose" class="mb-3"></purpose-select>
        <b-badge v-for="p in purposes" :key="p.id" variant="outlined-primary" class="mr-1 mb-1" style="line-height: 1.1em;">
          {{ p.label }}
          <b-button-close @click="() => removePurpose(p)" class="ml-1 mr-0" variant="info" style="font-size: 1em; line-height: 1.2em;"/>
        </b-badge>
      </b-form-group>
    </b-col>
    <b-col cols="12" xl="6">
      <b-form-group label="Durée de la visite">
        <duration-select v-model="duration" class="mb-3"></duration-select>
      </b-form-group>
    </b-col>
  </b-row>
  <b-row>
    <b-col cols="12" xl="6">
      <b-form-group label="ID Sphinx">
        <b-input v-model="idSphinx" />
      </b-form-group>
    </b-col>
  </b-row>
  <b-form-group>
    <b-form-checkbox v-model="consent" switch>
      Accord de l'entreprise
      <tips title="Concerne la diffusion des données liées à la visite auprès des autres acteurs locaux, mais n'ouvre pas l'accès aux coordonnées du contact dans l'entreprise"/>
    </b-form-checkbox>
  </b-form-group>
  <header class="bloc-title">
    <h4 class="bloc-title-content">Contact au sein de l'entreprise</h4>
  </header>
  <b-row>
    <b-col cols="12" xl="4">
      <b-form-group label="Civilité">
        <b-form-radio-group
          v-model="contact.civility"
          :options="[{ text: 'Non précisé', value: null }, { text: 'Madame', value: 'female' }, { text: 'Monsieur', value: 'male' }]"
          buttons
          name="radios-btn-warning"
          button-variant="outline-primary"
          ></b-form-radio-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" xl="4">
      <b-form-group label="Nom">
        <b-input v-model="contact.lastname" />
      </b-form-group>
    </b-col>
    <b-col cols="12" xl="4">
      <b-form-group label="Prénom">
        <b-input v-model="contact.firstname" />
      </b-form-group>
    </b-col>
  </b-row>
  <b-row>
    <b-col cols="12" xl="4">
      <b-form-group label="Fonction">
        <b-input v-model="contact.function" />
      </b-form-group>
    </b-col>
    <b-col cols="12" xl="4">
      <b-form-group label="Email">
        <b-input v-model="contact.email" type="email"/>
      </b-form-group>
    </b-col>
    <b-col cols="12" xl="4">
      <b-form-group label="Téléphone">
        <b-input v-model="contact.phone"/>
      </b-form-group>
    </b-col>
    <b-col cols="12" xl="4">
      <b-form-group >
        Employeur
        <b-form-checkbox v-model="contact.employee" switch>
          Employé
        </b-form-checkbox>
      </b-form-group>
    </b-col>
  </b-row>
  <header class="bloc-title">
    <h4 class="bloc-title-content">Suivi de la visite</h4>
  </header>
  <b-row>
    <b-col cols="12" xl="4">
      <b-form-group label="Date de prochaine visite">
          <datepicker :monday-first="true" :highlighted="{ customPredictor: d => d > new Date() }" :language="fr" :bootstrap-styling="true" v-model="dateNext" format="dd/MM/yyyy"></datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group label="Compte rendu de la visite">
      <b-form-textarea v-model="comment" rows="4"></b-form-textarea>
    </b-form-group>
    <b-form-group label="Compte rendu de la visite (PDF / Image / Doc / Docx)">
      <template v-for="(file, i) in attachments">
        <b-row :key="i">
          <b-col cols="8">
            <a href="#" @click.prevent="() => onDowload(file)" v-if="file.id">{{ file.name }}</a>
            <template v-else>{{ file.name }}</template>
            <b-button class="float-right" @click="deleteAttachment(file)" variant="danger" size="sm">
              <unicon name="trash-alt"></unicon>
            </b-button>
          </b-col>
          <b-col>
            <b-progress
              v-if="(file.modelState.progress > 0 && file.modelState.progress < 100) || !file.id"
              :value="file.modelState.progress">
            </b-progress>
          </b-col>
        </b-row>
      </template>
      <b-form-file placeholder="Choisissez des fichiers" v-model="moreAttachments" multiple ></b-form-file>
    </b-form-group>
    <b-btn v-if="!id" type="submit" variant="primary" class="float-right" :disabled="!isValid || state.saving">
      <b-spinner small variant="ligth" type="grow" v-if="state.saving"/>Enregistrer
    </b-btn>
    <b-btn v-else type="submit" variant="primary" class="float-right" :disabled="!isValid || state.saving">
      <b-spinner small variant="ligth" type="grow" v-if="state.saving"/>Suite
    </b-btn>
  </b-form>
</template>
<script>
import Siret from '@/views/siret/model'
import SiretSelect from '@/views/siret/select'
import PurposeSelect from '@/views/visit_purpose/select'
import DurationSelect from '@/views/duration/select'
import Visit from '@/views/visit/model'
import model from '@/views/model.mixin'
import pick from 'ramda/src/pick'
import Datepicker from 'vuejs-datepicker'
import { fr } from 'vuejs-datepicker/dist/locale'
import format from 'date-fns/format'
import Attachment from '@/views/attachment/model'
import Mime from 'mime'
import download from '@/views/download'
import Purpose from '@/views/visit_purpose/model'
import Tips from '@/views/tips'

export default {
  name: 'gip.visit.form',
  props: {
    value: Visit
  },
  components: {
    Datepicker,
    SiretSelect,
    PurposeSelect,
    DurationSelect,
    Tips
  },
  mixins: [model('value')],
  data () {
    return {
      saving: false,
      moreAttachments: [],
      siret: this.value.siret || new Siret(),
      fr,
      purpose: new Purpose(),
      isValidContact: false
    }
  },
  watch: {
    moreAttachments (files) {
      for (let file of files) {
        if (['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'].includes(Mime.getExtension(file.type))) {
          const load = new Attachment({
            file,
            name: file.name
          })
          this.value.attachments.push(load)
          load.save()
            .then(
              () => this.$forceUpdate(),
              () => {
                this.value.attachments.splice(this.value.attachments.indexOf(load), 1)
                this.warnWrongFileType(file.name)
              }
            )
        } else {
          this.warnWrongFileType(file.name)
        }
      }
    },
    contact: {
      handler: function () {
        this.isValidContact = this.contact.isValid(this.contact)
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    isValid () {
      return this.value.isValid(this) && this.isValidContact
    }
  },
  mounted () {
    if (this.value.id) {
      this.value.load().then(value => {
        Object.assign(this, value)
      })
    } else if (this.value.fromId) {
      const fromVisit = new Visit({ id: this.value.fromId })
      fromVisit.load().then(() => {
        this.siret = fromVisit.siret
        this.contact = fromVisit.contact
        this.duration = fromVisit.duration
        this.purposes.splice(0, this.purposes.length, ...fromVisit.purposes)
      })
    }
  },
  methods: {
    onSubmit () {
      const args = {
        without: ['user', 'trades', 'fromId'],
        siret: { fn: siret => Number(siret.id) },
        duration: { fn: duration => Number(duration.id) },
        date: { fn: date => format(new Date(this.date), 'yyyy-MM-dd HH:mm:ss') },
        childs: {
          attachments: { fn: a => Number(a.id) },
          contact: {
            without: ['id']
          },
          purposes: { fn: purpose => Number(purpose.id) }
        }
      }
      if (!this.date) {
        args.without.push('date')
        delete args.date
      }
      const title = this.value.id ? 'Edition d\'une visite' : 'Création d\'une visite'
      this.value
        .setData(pick(this.value.properties, this))
        .save(args)
        .then(value => {
          this.$notify({
            content: 'La visite a été enregistrée',
            title
          })
          this.$router.push({
            name: 'visit_details',
            params: { id: value.id }
          })
        })
        .catch((error) => {
          this.handleError(error)
        })
    },
    warnWrongFileType (filename) {
      this.$bvToast.toast(`Ce format de fichier n'est pas autorisé`, {
        title: filename,
        variant: 'danger',
        solid: true
      })
    },
    onDowload (file) {
      file
        .getBlob()
        .then(blob => download(blob, file.name))
    },
    deleteAttachment (file) {
      this.attachments.splice(this.attachments.indexOf(file), 1)
    },
    addPurpose (purpose) {
      if (this.purposes.findIndex(p => p.id === purpose.id) === -1) {
        this.purposes.push(purpose)
      }
      this.purpose = new Purpose()
    },
    removePurpose (purpose) {
      const at = this.purposes.findIndex(p => p.id === purpose.id)
      if (at >= 0) {
        this.purposes.splice(at, 1)
      }
    }
  }
}
</script>
<style>

.attachment-delete {
  float: right
}
</style>
