import Model from '@/views/model'

class EmploymentZone extends Model {
  constructor (data = {}) {
    super(data)
  }
  static search (options) {
    return super.search(options, EmploymentZone)
  }
}

EmploymentZone.prototype.properties = Model.prototype.properties.concat(['code', 'name', 'lat', 'lng'])
EmploymentZone.prototype.baseUrl = '/crud/employment_zone'

export default EmploymentZone
