<template>
  <b-spinner v-if="state.loading" variant="primary"/>
  <b-form v-else @submit.prevent="save">
    <b-card class="visit-trade-skills_title">
      <span class="visit-trade-skills_media">
        <unicon name="suitcase" fill="white"/>
        <span>Métier</span>
      </span>
      <h3>{{ trade.name }}</h3>
    </b-card>

    <header class="bloc-title">
      <h1 class="bloc-title-content">Compétences ajoutées</h1>
    </header>

    <visit-trade-skill-form
      v-model="visitTradeSkill"
      ref="skill"
      @change="onSkillUpdate"
      class="mt-5"
      @text="onSkillUpdate"
      :trade="trade"
      >
      <template v-slot:actions>
        <div>
          <b-button variant="primary" @click="onAddSkill" :disabled="!isAddSkillUnable">
            <unicon name="plus"/>
          </b-button>
          <b-modal ref="confirm" title="Ajouter la compétence" centered>
            <p class="my-4">Cette compétence n'existe pas dans notre référentiel, souhaitez vous l'ajouter ?</p>
            <template #modal-footer="{ ok, cancel }">
              <b-button @click="cancel">Annuler</b-button>
              <b-button variant="primary" @click="() => addSkill(ok)" :disabled="isSavingSkill">Ajouter</b-button>
            </template>
          </b-modal>
        </div>
      </template>
    </visit-trade-skill-form>

    <skill-table :skills="pimpeds" :columns="baseFields">
      <template v-slot:utility="{skill}">
        <galaxy v-model="skill.utility" writable/>
      </template>
    </skill-table>
    <header class="bloc-title">
      <h1 class="bloc-title-content">Compétences</h1>
    </header>
    <skill-table :skills="specifics" :columns="baseFields">
      <template v-slot:utility="{skill}">
        <galaxy v-model="skill.utility" writable banable/>
      </template>
      <template v-slot:ecological="{skill}">
        <b-form-checkbox v-model="skill.ecological" switch size="lg"/>
      </template>
      <template v-slot:numerical="{skill}">
        <b-form-checkbox v-model="skill.numerical" switch size="lg"/>
      </template>
      <template v-slot:technological="{skill}">
        <b-form-checkbox v-model="skill.technological" switch size="lg"/>
      </template>
    </skill-table>

    <slot name="actions">
      <b-button type="submit" variant="primary" class="float-right" :disabled="state.saving">
        <b-spinner v-if="state.saving" variant="light" type="grow" small/>
        Enregistrer
      </b-button>
    </slot>

  </b-form>
</template>
<script>
import VisitTradeSkill from '@/views/visit_trade_skill/model'
import SkillTable from '@/views/visit_trade_skill/table'
import VisitTrade from '@/views/visit_trade/model'
import Skill from '@/views/skill/model'
import model from '@/views/model.mixin'
import VisitTradeSkillForm from '@/views/visit_trade_skill/form'
import Galaxy from '@/views/utility/galaxy'

export default {
  name: 'gip.visit.trade.skills.score.form',
  props: {
    value: VisitTrade
  },
  components: { SkillTable, VisitTradeSkillForm, Galaxy },
  mixins: [model('value')],
  data () {
    const skill = new VisitTradeSkill({ utility: { score: 0 } })
    skill.skill.on('update', this.onSkillUpdate.bind(this))
    return {
      baseFields: [
        { key: 'skill.name', label: 'Compétences' },
        { key: 'utility' }
      ],
      visitTradeSkill: skill,
      isAddSkillUnable: false,
      lastInput: null,
      isSavingSkill: false
    }
  },
  computed: {
    specifics () {
      return this.skills.filter(vts => this.trade.skills.find(s => vts.skill.id === s.skill.id))
    },
    pimpeds () {
      return this.skills.filter(vts => !this.trade.skills.find(s => vts.skill.id === s.skill.id))
    }
  },
  methods: {
    onSkillUpdate () {
      this.isAddSkillUnable = this.canAddSkill()
      let dom = this.$el.querySelector(`tr[data-pk="${this.visitTradeSkill.skill.id}"]`)
      let prev = this.$el.querySelector('tr.activeSkill')
      if (prev) {
        prev.classList.toggle('activeSkill')
      }
      if (dom) {
        dom.classList.toggle('activeSkill')
      }
    },
    onAddSkill () {
      let name = this.$refs.skill.$refs.skill.lastInput
      this.$refs.skill.onSubmit()
      if (typeof this.visitTradeSkill.skill.id === 'number' && name === this.visitTradeSkill.skill.name) {
        this.addSkill()
      } else if (name.length > 2) {
        this.visitTradeSkill.setData({ skill: new Skill({ name }) })
        this.$refs.confirm.show()
      }
    },
    async addSkill (ok) {
      let s = this.visitTradeSkill.skill
      if (!s.id) {
        this.isSavingSkill = true
        await s.save({ without: ['reviewed', 'numerical', 'ecological', 'technological'] })
        this.isSavingSkill = false
        ok()
        this.$bvToast.toast(`La compétence '${s.name}' est ajoutée au référentiel.`, {
          title: `Nouvelle compétence`,
          solid: true,
          variant: 'success'
        })
      }
      this.visitTradeSkill.removeListener()
      const exist = this.skills.find(s => s.skill.id === this.visitTradeSkill.skill.id)
      if (exist) {
        exist.utility.setData(this.visitTradeSkill.utility.marshall())
      } else {
        this.skills.push(new VisitTradeSkill(this.visitTradeSkill.marshall()))
      }
      const skill = new VisitTradeSkill({ utility: { score: 0 } })
      skill.skill.on('update', this.onSkillUpdate.bind(this))
      this.visitTradeSkill = skill
      this.isAddSkillUnable = this.canAddSkill()
      this.$refs.skill.reset()
    },
    canAddSkill () {
      let hasSkillId = typeof this.visitTradeSkill.skill.id === 'number'
      let isNew = !hasSkillId && this.$refs.skill.$refs.skill.lastInput.length > 2
      let noted = Number(this.visitTradeSkill.utility.score) !== 0
      let isBan = Number(this.visitTradeSkill.utility.score) < 0
      return (hasSkillId || (isNew && !isBan)) && noted
    },
    save () {
      const args = {
        with: ['skills'],
        skills: {
          fn: all => all
            .filter(s => s.utility.score !== null && Number(s.utility.score) !== 0)
            .map(s => {
              const me = {
                utility: { fn: u => u.id },
                skill: { fn: s => s.id },
                without: ['ecological', 'numerical', 'reviewed', 'technological']
              }
              if ('id' in s && s.id !== null) {
                me.with = ['id']
              } else {
                me.without.push('id')
              }
              return s.marshall(me)
            })
        }
      }
      this.value
        .save(args)
        .then(
          () => {
            this.$notify({
              content: `Compétences de ${this.value.trade.name} modifiées`,
              title: 'Compétences métier'
            })
            this.$emit('done', this.value)
          }
        ).catch(this.handleError.bind(this))
    }
  }
}
</script>
<style lang="scss">
  @import '@/assets/custom-vars.scss';
  .visit-trade-skills_title {
    max-width: 50%;
    margin: 0 auto;
    .card-body {
      display: flex;
      align-items: center;
    }
    .unicon {
      margin: 0 1em;
    }
    .visit-trade-skills_media {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
    }
    h3 {
      display: inline-block;
      background: #ffffff77;
      border-radius: 10px;
      padding: 0.2em 0.4em;
      margin: 0;
    }
  }
  .activeSkill td {
    animation-name: checkme;
    animation-duration: 1s;
    animation-fill-mode: both;
    transform-origin: left center;
  }

  @keyframes checkme {
    0% {transform: scale(1.1);}
    30% {transform: scale(0.8);}
    50% {transform: scale(1.2);}
    60% {transform: scale(0.9);}
    80% {transform: scale(1.1);}
    100% {transform: scale(1);}
  }
  @media screen and (max-width: 1200px) {
    .visit-trade-skills_title {
      max-width: 100%;
    }
  }
</style>
