import Model from '@/views/model'
import City from '@/views/city/model'

class Organisation extends Model {
  constructor (data = {}) {
    super(data)
  }
  static search (options) {
    return super.search(options, Organisation)
  }
}

Organisation.prototype.schema = {
  collections: {},
  includes: {
    city: City
  }
}
Organisation.prototype.properties = Model.prototype.properties.concat(['name'])
Organisation.prototype.baseUrl = '/crud/organisation'

export default Organisation
