<template>
  <div>
    <header>
      <h3>Modifier mon profil</h3>
    </header>
    <user-form @submit="onSave" v-model="value">
      <template v-slot:actions>
        <div class="text-right">
          <b-button :to="{ name: 'update_credential' }" variant="primary" class="mr-5">Changer de mot de passe</b-button>
          <b-button type="submit" variant="primary">
            <b-spinner small type="grow" variant="light" v-if="state.loading || state.saving"/>
            Enregistrer
          </b-button>
        </div>
      </template>
    </user-form>
  </div>
</template>
<script>
import UserForm from './form'
import model from '@/views/model.mixin'
import User from './model'
import { identityNum } from '@/views/fun'

export default {
  name: 'gip.user.profil.edit',
  props: {
    value: {
      type: User,
      default: function () {
        return this.$user
      }
    }
  },
  components: {
    UserForm
  },
  mixins: [model('value')],
  methods: {
    onSave () {
      const args = {
        reason: identityNum,
        mission: identityNum,
        organisation: identityNum,
        cityPostcode: identityNum,
        avatar: identityNum,
        without: ['roles'],
        childs: {
          reasons: { fn: reason => Number(reason.id) },
          organisations: { fn: organisation => Number(organisation.id) }
        }
      }
      if (!this.$user.roles.includes('ROLE_ADMIN')) {
        args.without.push('enabled')
      }
      this.value.save(args)
        .then(() => {
          if (this.$user.id === this.value.id) {
            this.$user.setData(this.value.marshall())
          }
          this.$notify({
            content: 'Les modifications ont été enregistrées',
            title: 'Profil mis à jour'
          })
          this.$router.push({ name: 'home' })
        })
        .catch((error) => {
          this.handleError(error)
        })
    }
  }
}
</script>
