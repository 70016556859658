import VisitTrade from '@/views/visit_trade/model'
import Model from '@/views/model'
import Siret from '@/views/siret/model'
import User from '@/views/user/model'
import Purpose from '@/views/visit_purpose/model'
import Duration from '@/views/duration/model'
import Attachment from '@/views/attachment/model'
import Contact from '@/views/contact/model'
import { required } from '@/views/validation/validator'
import format from 'date-fns/format'

const schema = {
  collections: {
    trades: VisitTrade,
    purposes: Purpose,
    attachments: Attachment
  },
  includes: {
    siret: Siret,
    duration: Duration,
    user: User,
    contact: Contact
  }
}
class Visit extends Model {
  static search (options) {
    return super.search(options, Visit)
  }

  static async searchResultsExportExcel (search) {
    let data = {}
    if (search.dateFrom) {
      data['dateMin'] = format(new Date(search.dateFrom), 'yyyy-MM-dd 00:00:00')
    }
    if (search.dateTo) {
      data['dateMax'] = format(new Date(search.dateTo), 'yyyy-MM-dd 23:59:59')
    }
    if (search.companyName) {
      data['company'] = search.companyName
    }
    if (search.siret) {
      data['sirets'] = [search.siret]
    }
    const response = await Model.api.then(api => api.get('/export/visit', { params: data }))
    return response
  }
}

Visit.prototype.properties = Model.prototype.properties.concat([
  'siret',
  'date',
  'dateNext',
  'duration',
  'purposes',
  'comment',
  'attachments',
  'consent',
  'trades',
  'user',
  'contact',
  'idSphinx'
])
Visit.prototype.baseUrl = '/crud/visit'
Visit.prototype.schema = schema

Visit.prototype.validator = {
  validate (value) {
    const requiredFields = [
      'siret',
      'purposes'
    ]
    let message = null
    message = required(requiredFields, value)
    if (message) {
      return { error: message }
    }
    return 'valid'
  }
}

export default Visit
