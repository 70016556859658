import Model from '@/views/model'

class Duration extends Model {
  constructor (data = {}) {
    super(data)
    if (this.duration) {
      this.duration = Number(this.duration.match(/P0Y0M0DT(.*)H0M0S/)[1])
    }
  }
  static search (options) {
    return super.search(options, Duration)
  }
  async load () {
    super.load().then(() => {
      this.duration = Number(this.duration.match(/P0Y0M0DT(.*)H0M0S/)[1])
      return this
    })
  }
}

Duration.prototype.properties = Model.prototype.properties.concat(['duration', 'label'])
Duration.prototype.baseUrl = '/crud/duration'

export default Duration
