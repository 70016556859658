import Model from './model'
import curry from 'ramda/src/curry'
import { handleError, onAgreementError, notifyError, handlePromiseError } from '@/views/error/handlers'

export default function (...props) {
  return {
    data () {
      return Object.assign({},
        ...props
          .filter(prop => this[prop] && this[prop] instanceof Model)
          .map(register(this))
      )
    },
    watch: Object.assign({},
      ...props
        .map(prop => ({ [prop]: function () {
          Object.assign(this, register(this, prop))
        } }))
    ),
    methods: {
      notifyError,
      handleError,
      onAgreementError,
      handlePromiseError
    },
    computed: {
      isValid () {
        return props.every(prop => this[prop].isValid(this))
      }
    }
  }
}

const register = curry(function (vue, prop) {
  // @fixme stop listening on destroy
  vue[prop].on('update', v => {
    return assign(vue, v.marshall({}, true))
  })
  vue[prop].on('state', (state, value) => {
    vue.state[state] = Boolean(value)
  })
  return Object.assign({ state: vue[prop].modelState }, vue[prop].marshall({}, true))
})

function assign (to, from) {
  Object.keys(from).forEach(k => {
    if (Array.isArray(to[k])) {
      to[k].splice(0, to[k].length, ...from[k])
    } else {
      to[k] = from[k]
    }
  })
  return to
}
