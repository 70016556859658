<template>
  <b-container>
    <h1>Se connecter</h1>
    <b-row>
      <b-col>
        <b-form @submit.prevent="onSubmit">
          <b-form-group label="Adresse email*">
            <b-input v-model="email" type="email" placeholder="@" required/>
          </b-form-group>
          <b-form-group label="Mot de passe*">
            <b-input v-model="password" type="password" placeholder="mot de passe" required/>
            <b-link class="" :to="{ name: 'password_request' }">Mot de passe oublié ?</b-link>
          </b-form-group>
          <b-btn type="submit" variant="primary">
            <template v-if="state.logging"><b-spinner small type="grow"></b-spinner></template>
            <template v-else>Se connecter</template>
          </b-btn>
          <p class="login_message">Vous n'avez pas de compte ? <b-link :to="{ name: 'signin' }">Inscrivez-vous.</b-link></p>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import model from '@/views/model.mixin'

export default {
  name: 'gip.login',
  mixins: [model('$user')],
  data () {
    return {
      email: '',
      password: ''
    }
  },
  mounted () {
    if (this.$user.isAuth) {
      this.$router.push({
        name: 'home'
      })
    }
  },
  methods: {
    onSubmit () {
      this.$user
        .login({ email: this.email, password: this.password })
        .then(
          () => {
            this.$router.push({ name: 'home' })
          },
          err => {
            this.handlePromiseError(err, err => {
              if (err.response && (err.response.status === 404 || err.response.status === 401)) {
                this.$bvToast.toast('Email/mot de passe incorrect', {
                  title: 'Erreur',
                  variant: 'danger',
                  toaster: 'b-toaster-top-center',
                  solid: true
                })
                return true
              } else {
                this.handleError(err)
              }
            })
          }
        )
    }
  }
}
</script>
<style>
.login-container {
  max-width: 500px;
  margin-top: 20%;
}
</style>
