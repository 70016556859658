import { translate } from './translate'
import debug from 'debug'
const log = debug('app')

export function handleError (err) {
  log('handled error : %o', err)
  this.handlePromiseError(err, this.notifyError.bind(this))
}

export function handlePromiseError (err, cb = function () {}) {
  if (err instanceof Promise) {
    return err.catch(err => this.handlePromiseError(err, cb))
  } else if (this.onAgreementError(err)) {
    return
  }
  return cb(err)
}
export function onAgreementError (err) {
  if (this.$user.onAgreementError(err)) {
    this.$router.push({
      name: 'user_agreement'
    })
    return true
  }
}

export function notifyError (err) {
  // toast with request response (mainly for debugging)
  if (err && err.response && err.response.request && err.response.request.response) {
    this.$bvToast.toast(translate(err.response.request.response), {
      title: 'Erreur',
      variant: 'danger',
      toaster: 'b-toaster-top-center',
      solid: true
    })
  }
}
