const uniqCache = new Map()
let countCache = 0

export default {
  install: function (Vue, options) {
    Vue.prototype.$uniq = function uniq (model) {
      if (!uniqCache.has(model)) {
        uniqCache.set(model, countCache)
        countCache++
      }
      return uniqCache.get(model)
    }
  }
}
