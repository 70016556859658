<script>
import Siret from '@/views/siret/model'
import Selector from '@/views/selector'

export default {
  name: 'gip.selector.siret',
  extends: Selector,
  props: {
    value: Siret,
    type: {
      default: () => Siret
    }
  },
  data () {
    return {
      serializers: ['default']
    }
  },
  methods: {
    baseFilters (val, field) {
      return [{
        type: '\\App\\Model\\Filter\\OrFilter',
        filters: [
          {
            type: '\\App\\Model\\Filter\\AndFilter',
            filters: val.trim().split(' ').map(val => ({
              type: '\\App\\Model\\Filter\\LikeFilter',
              field,
              value: JSON.stringify(`%${val}%`)
            }))
          },
          ...val.trim().split(' ').map(val => ({
            type: '\\App\\Model\\Filter\\LikeFilter',
            field: 'siret',
            value: JSON.stringify(`%${val}%`)
          }))
        ]
      }]
    }
  }
}
</script>
