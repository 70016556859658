<template>
  <div id="user-content">
    <img id="picto-show-menu" class="picto-user-menu"
      v-if="reduced"
      @click.prevent="showMenu"
      src="../assets/menu_show.png"
      alt="Afficher le menu"
      />
    <img id="picto-logout" class="picto-user-menu"
      @click="logout"
      v-b-popover.hover.top="'Déconnexion'"
      src="../assets/menu_off.png"
      alt="Déconnexion"
      />
    <img id="picto-edit-profil" class="picto-user-menu"
      @click="editProfil"
      v-b-popover.hover.top="'Modification du profil'"
      src="../assets/menu_edit.png"
      alt="Modification du profil"
      />
    <img id="picto-reduce-menu" class="picto-user-menu"
      @click.prevent="reduceMenu"
      v-b-popover.hover.top="'Réduire'"
      src="../assets/menu_reduce.png"
      alt="Réduire le menu"
      />
    <div class="avatar-container user-picture">
      <avatar v-model="avatar" :gender="gender" width="150px"/>
    </div>
    <div class="user-name">
      <img id="menu-angle-top" src="../assets/menu_angle_top.png" alt="angle arrondi du menu"/>
      {{ firstname }}<br>{{ lastname }}
      <img id="menu-angle-bottom" src="../assets/menu_angle_bottom.png" alt="angle arrondi du menu"/>
    </div>
    <div class="user-infos">
      <b-row v-if="address" align-v="center">
        <b-col cols="2" class="text-center">
          <img class="picto-user-profile" src="../assets/home.png" alt="adresse"/>
        </b-col>
        <b-col>
          {{ address }}<br>
          {{ cityPostcode.postcode.name }} {{ cityPostcode.name }}
        </b-col>
      </b-row>
      <b-row v-if="structure" align-v="center">
        <b-col cols="2" class="text-center">
          <img class="picto-user-profile" src="../assets/company.png" alt="entreprise"/>
        </b-col>
        <b-col>{{ structure }}</b-col>
      </b-row>
      <b-row v-if="phone" align-v="center">
        <b-col cols="2" class="text-center">
          <img class="picto-user-profile" src="../assets/phone.png" alt="téléphone"/>
        </b-col>
        <b-col>{{ phone }}</b-col>
      </b-row>
      <b-row v-if="email" align-v="center">
        <b-col cols="2" class="text-center">
          <img class="picto-user-profile" src="../assets/mail.png" alt="email"/>
        </b-col>
        <b-col>{{ email }}</b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import model from '@/views/model.mixin'
import Avatar from '@/views/attachment/avatar.vue'

export default {
  name: 'gip.user.menu',
  mixins: [model('$user')],
  components: {
    Avatar
  },
  data () {
    return {
      reduced: false
    }
  },
  methods: {
    logout () {
      this.$user.logout()
      window.location.hash = ''
      window.location.reload()
    },
    editProfil () {
      this.$emit('toggle')
      this.$router.push({ name: 'profil_edit' })
    },
    reduceMenu () {
      this.reduced = true
      this.$emit('reduced')
    },
    showMenu () {
      this.reduced = false
      this.$emit('reduced')
    }
  }
}
</script>
<style>
.nav-firstname {
  color: white;
}
.avatar-container {
  display: block;
  margin: 1em;
}
</style>
