import Model from '@/views/model'
import Skill from '@/views/skill/model'
import { sortBy } from '@/views/fun'

function factorise (cache) {
  const self = function (cat) {
    if (cache.has(cat.id)) {
      const c = cache.get(cat.id)
      if (!c.name) {
        c.setData(cat.marshall())
      }
      return c
    } else {
      cache.set(cat.id, cat)
      cat.children = cat.children.map(self)
    }
    return cat
  }
  return self
}

class Category extends Model {
  static search (options = {}) {
    return super.search(options, Category)
  }
  static deduplicate (categories, cache = new Map()) {
    categories.map(factorise(cache))
    Category.sort(categories)
    categories.forEach(c => {
      c.skills.forEach(s => {
        if (!s.categories) {
          s.categories = []
        }
        if (!s.categories.find(z => z.id === c.id)) {
          s.categories.push(c)
        }
      })
    })
    return categories
  }
  static sort (categories, sorted = []) {
    // avoid circular
    categories.forEach(category => {
      if (!sorted.includes(category.id)) {
        sorted.push(category.id)
        Category.sort(category.children, sorted)
      }
    })
    categories.sort(sortBy('name', -1))
  }
}

Category.prototype.properties = Model.prototype.properties.concat(['name', 'parents', 'children', 'skills'])
Category.prototype.schema = { collections: { skills: Skill, parents: Category, children: Category }, includes: {} }
Category.prototype.baseUrl = '/crud/soft_skill_category'

export default Category
