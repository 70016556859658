<template>
  <b-card class="visit-card-details">
    <b-spinner variant="primary" v-if="state.loading"/>
    <b-card-text v-else>
      <b-row class="user">
        <b-col cols="3" class="d-flex">
          <avatar width="50px" v-model="user.avatar" :gender="user.gender"/>
        </b-col>
        <b-col cols="9">
          <h3>{{ user.firstname }} {{ user.lastname }}<br><span>{{ user.organisations.map(o => o.name).join(', ') }}</span></h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ul>
            <li><span class="bold">ID :</span> {{ id }}</li>
            <li>
              <span class="bold">Entreprise :</span>
              <b-link :to="'/entreprise/'+siret.id">
                {{ siret.name }}
              </b-link>
            </li>
            <li><span class="bold">Date de la  visite :</span> {{ formatDate(date) }}</li>
            <li v-if="dateNext"><span class="bold">Date de la prochaine visite :</span> {{ formatDate(dateNext) }}</li>
            <li><span class="bold">Objets de la visite :</span> {{ purposes.map(p => p.label).join(', ') }}</li>
            <li>
              <span class="bold">Compte-rendu :</span>
               {{ comment }}
               <b-button :to="{ name: 'visit_edit', params: { id } }" variant="primary" size="sm" style="margin: 2px;" v-if="value.canEdit($user)">
                 <unicon name="pen"/>
               </b-button>
            </li>
            <li v-if="attachments.length > 0">
              <span class="bold">Compte rendu (fichier) :</span>
              <a
                v-for="file in attachments"
                :key="file.id"
                href="#"
                @click.prevent="() => onDowload(file)">
                {{ file.name }}
              </a>
            </li>
            <li><span class="bold">Accord de l'entreprise :</span> {{ consent ? 'Oui' : 'Non' }}</li>
            <li>
              <span class="bold">Contact :</span>
              {{ contact.civility == 'male' ? 'M.' : 'Mme' }} {{ contact.firstname }} {{ contact.lastname }} ({{ contact.function }})
            </li>
            <li v-if="contact.phone"><span class="bold">Téléphone :</span> {{ contact.phone }}</li>
            <li v-if="contact.email"><span class="bold">Email :</span> {{ contact.email }}</li>
          </ul>
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>
<script>
import Visit from '@/views/visit/model'
import model from '@/views/model.mixin'
import format from 'date-fns/format'
import Avatar from '@/views/attachment/avatar.vue'
import download from '@/views/download'

export default {
  name: 'gip.visit.card.details',
  props: {
    value: Visit
  },
  components: { Avatar },
  mixins: [model('value')],
  methods: {
    formatDate (date) {
      return format(new Date(date), 'dd/MM/yyyy')
    },
    onDowload (file) {
      file
        .getBlob()
        .then(blob => download(blob, file.name))
    }
  },
  computed: {
    canEdit () {
      return this.value.canEdit(this.$user)
    }
  }

}
</script>
