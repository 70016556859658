import Model from '@/views/model'
import VisitTradeSkill from '@/views/visit_trade_skill/model'
import VisitTradeLanguage from '@/views/visit_trade_language/model'
import VisitTradeSoftSkill from '@/views/visit_trade_softskill/model'
import Deadline from '@/views/deadline/model'
import Trade from '@/views/trade/model'
import WorkCondition from '@/views/work_condition/model'

const schema = {
  collections: {
    skills: VisitTradeSkill,
    softSkills: VisitTradeSoftSkill,
    languages: VisitTradeLanguage,
    workConditions: WorkCondition
  },
  includes: {
    deadline: Deadline,
    // deprecationDeadline: Deadline,
    trade: Trade
  }
}

class VisitTrade extends Model {
  static search (options) {
    return super.search(options, VisitTrade)
  }

  static async exportExcel (id, type, emails) {
    const data = {
      format: type,
      emails: emails.split(',')
    }
    const response = await Model.api.then(api => api.get('/export/visit-trade/' + id, { params: data }))
    return response
  }
}

VisitTrade.prototype.properties = Model.prototype.properties.concat([
  'trade',
  'deadline',
  'quantity',
  'skills',
  'languages',
  'workConditions',
  'workConditionsComment',
  'softSkills',
  'visit',
  'companyReorganization',
  'newJob',
  'renewJob',
  'recruitmentNeeded',
  'keyJob',
  // 'deprecated',
  // 'deprecatedQuantity',
  // 'deprecationDeadline',
  'extra'
])
VisitTrade.prototype.schema = schema
VisitTrade.prototype.baseUrl = '/crud/visit_trade'

export default VisitTrade
