import Attachment from '@/views/attachment/model'
const cache = new Map()

class Avatar extends Attachment {
  setData (data) {
    if (data && data.id) {
      if (cache.has(data.id)) {
        this.setData({ preview: cache.get(data.id) })
      } else {
        this.getB64().then(({ preview }) => cache.set(data.id, preview))
      }
    }
    return super.setData(data)
  }
}
Avatar.prototype.type = 'avatar'

export default Avatar
