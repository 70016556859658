<template>
  <div>
    <b-form >
      <b-row>
        <b-col cols="6">
          <b-form-group label="Compétence à ajouter*" label-align="center">
            <skill-select
              v-model="skill"
              ref="skill"
              @input="select"
              @text="t => $emit('text', t)"
              :trade="trade"
              class="text-center"
              >
            </skill-select>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Score*" label-align="left">
            <galaxy v-model="utility" writable @change="$emit('change', value)" class="text-center"/>
          </b-form-group>
        </b-col>

        <template v-if="impact">
          <b-col>
            <b-form-group label="Impact numérique" label-align="center">
              <b-form-checkbox v-model="numerical" switch class="text-center" size="lg"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Impact écologique" label-align="center">
              <b-form-checkbox v-model="ecological" switch class="text-center" size="lg"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Impact technologique" label-align="center">
              <b-form-checkbox v-model="technological" switch class="text-center" size="lg"/>
            </b-form-group>
          </b-col>
        </template>

        <b-col class="m-auto">
          <slot name="actions"></slot>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<script>
import VisitTradeSkill from '@/views/visit_trade_skill/model'
import SkillSelect from '@/views/skill/selector'
import utilities from '@/views/utility/cache'
import Galaxy from '@/views/utility/galaxy'
import model from '@/views/model.mixin'
import Skill from '@/views/skill/model'
import pick from 'ramda/src/pick'
import Trade from '@/views/trade/model'

export default {
  name: 'gip.visit.trade.skill.form',
  props: {
    value: VisitTradeSkill,
    exclude: Array,
    impact: {
      type: Boolean,
      default () {
        return false
      }
    },
    trade: Trade
  },
  components: { SkillSelect, Galaxy },
  mixins: [model('value')],
  methods: {
    reset () {
      this.skill.removeListener()
      this.utility.removeListener()
      this.skill = new Skill()
      this.value.setData({ utility: utilities.list[0].clone() })
      this.$refs.skill.reset()
    },
    select (skill) {
      this.value.setData({ skill })
      this.$emit('change', this.value)
    },
    create () {
      return new Skill({
        name: this.$refs.skill.lastInput
      })
    },
    onSubmit () {
      this.value.setData(pick(this.value.properties, this))
    }
  },
  mounted () {
    utilities.load()
  }
}
</script>
