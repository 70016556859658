import Model from '@/views/model'
import Utility from '@/views/utility/model'

class Utilities {
  constructor () {
    this.list = []
    this.loading = false
  }
  async load () {
    if (this.list.length === 0) {
      if (!this.loading) {
        this.loading = Model.api.then(Utility.search)
        return this.loading.then(({ results }) => {
          this.list.splice(0, this.list.length, ...results)
          this.list.sort((a, b) => Number(a.score) - Number(b.score))
          this.loading = false
        })
      }
    }
    return this.loading || Promise.resolve()
  }
}

export default new Utilities()
