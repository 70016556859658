<script>
import Softskill from '@/views/softskill/model'
import Selector from '@/views/selector'
import Category from '@/views/softskill_category/model'

export default {
  name: 'gip.softskill.selector',
  extends: Selector,
  props: {
    value: Softskill,
    type: {
      default: () => Softskill
    }
  },
  data () {
    return {
      filters: {
        type: '\\App\\Model\\Filter\\EqualsFilter',
        field: 'reviewed',
        value: JSON.stringify(true)
      }
    }
  },
  methods: {
    resultValue (result) {
      const categories = result.categories.map(c => c.name.substring(0, 10)).join(', ')
      return result[this.field] + (categories.length ? ` (${categories})` : '')
    },
    async search (val) {
      if (val.length >= this.minLength) {
        this.lastInput = val
        try {
          this.loading = Promise.all([
            this.type.search({
              filters: this.baseFilters(val, this.field)
                .concat(this.filters),
              serializers: this.serializers || ['default']
            }).then(data => data.results),
            Category.search({
              filters: this.baseFilters(val, this.field),
              serializers: this.serializers || ['default']
            }).then(data => {
              return [].concat(...data.results.map(r => r.skills.map(s => s.setData({ categories: [r] }))))
            })
          ])
          const results = await this.loading
          return []
            .concat(...results)
            .filter((s, i, a) => a.findIndex(o => o.id === s.id) === i)
            .sort((a, b) => {
              if (a.name < b.name) {
                return -1
              }
              if (a.name > b.name) {
                return 1
              }
              return 0
            })
        } catch (err) {
          this.handleError(err)
        }
      }
      this.loading = false
      return []
    }
  }
}
</script>
