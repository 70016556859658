<template>
  <b-spinner v-if="state.loading" variant="primary"/>
  <b-container fluid v-else>
    <b-card class="visit-trade-skills_title">
      <span class="visit-trade-skills_media">
        <unicon name="suitcase" fill="white"/>
        <span>Métier</span>
      </span>
      <h3>{{ trade.name }}</h3>
    </b-card>

    <header class="bloc-title">
      <h1 class="bloc-title-content">Compétences ajoutées</h1>
    </header>

    <skill-table :skills="pimpeds" :columns="baseFields">
      <template v-slot:utility="{skill}">
        <galaxy v-model="skill.utility"/>
      </template>
      <template v-slot:ecological="{skill}">
        <b-form-checkbox v-model="skill.ecological" switch disabled size="lg"/>
      </template>
      <template v-slot:numerical="{skill}">
        <b-form-checkbox v-model="skill.numerical" switch disabled size="lg"/>
      </template>
      <template v-slot:technological="{skill}">
        <b-form-checkbox v-model="skill.technological" switch disabled size="lg"/>
      </template>
    </skill-table>

    <header class="bloc-title">
      <h1 class="bloc-title-content">Compétences</h1>
    </header>
    <skill-table :skills="specifics" :columns="baseFields">
      <template v-slot:utility="{skill}">
        <galaxy v-model="skill.utility" banable/>
      </template>
      <template v-slot:ecological="{skill}">
        <b-form-checkbox v-model="skill.ecological" switch disabled size="lg"/>
      </template>
      <template v-slot:numerical="{skill}">
        <b-form-checkbox v-model="skill.numerical" switch disabled size="lg"/>
      </template>
      <template v-slot:technological="{skill}">
        <b-form-checkbox v-model="skill.technological" switch disabled size="lg"/>
      </template>
    </skill-table>

    <header class="bloc-title">
      <h1 class="bloc-title-content">Softskills</h1>
    </header>

    <soft-skill-table :softSkills="softSkills" :columns="softSkillsFields" :busy="state.loading">
      <template v-slot:utility="{softSkill}">
        <galaxy v-model="softSkill.utility" />
      </template>
      <template v-slot:table-busy>
        <div class="text-center">
          <b-spinner variant="primary"/>
        </div>
      </template>
    </soft-skill-table>

    <header class="bloc-title">
      <h1 class="bloc-title-content">Langues</h1>
    </header>

    <language-table :languages="languages" :columns="languagesFields" :busy="state.loading">
      <template v-slot:utility="{language}">
        <galaxy v-model="language.utility"/>
      </template>
      <template v-slot:table-busy>
        <div class="text-center">
          <b-spinner variant="primary"/>
        </div>
      </template>
    </language-table>

    <header class="bloc-title">
      <h1 class="bloc-title-content">Conditions de travail</h1>
    </header>

    <b-table responsive striped :items="workConditions" :fields="[{ key: 'name', label: '' }]" class="darker" :busy="state.loading" primary-key="id">
    </b-table>

  </b-container>
</template>
<script>
import SkillTable from '@/views/visit_trade_skill/table'
import VisitTrade from '@/views/visit_trade/model'
import model from '@/views/model.mixin'
import Galaxy from '@/views/utility/galaxy'
import SoftSkillTable from '@/views/visit_trade_softskill/table'
import LanguageTable from '@/views/visit_trade_language/table'

export default {
  name: 'gip.visit.trade.details',
  props: {
    value: VisitTrade
  },
  components: { SkillTable, Galaxy, SoftSkillTable, LanguageTable },
  mixins: [model('value')],
  data () {
    return {
      baseFields: [
        { key: 'skill.name', label: 'Compétences' },
        { key: 'utility' },
        { key: 'numerical' },
        { key: 'ecological' },
        { key: 'technological' }
      ],
      softSkillsFields: [
        { key: 'softSkill.name' },
        { key: 'utility' }
      ],
      languagesFields: [
        { key: 'language.name', label: 'Langue' },
        { key: 'utility' }
      ]
    }
  },
  computed: {
    specifics () {
      return this.skills.filter(vts => this.trade.skills.find(s => vts.skill.id === s.skill.id))
    },
    pimpeds () {
      return this.skills.filter(vts => !this.trade.skills.find(s => vts.skill.id === s.skill.id))
    }
  }
}
</script>
<style lang="scss">
  @import '@/assets/custom-vars.scss';
  .visit-trade-skills_title {
    max-width: 50%;
    margin: 0 auto;
    .card-body {
      display: flex;
      align-items: center;
    }
    .unicon {
      margin: 0 1em;
    }
    .visit-trade-skills_media {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
    }
    h3 {
      display: inline-block;
      background: #ffffff77;
      border-radius: 10px;
      padding: 0.2em 0.4em;
      margin: 0;
    }
  }
  @media screen and (max-width: 1200px) {
    .visit-trade-skills_title {
      max-width: 100%;
    }
  }
</style>
