<template>
  <b-container fluid>
    <b-row align-h="center">
      <b-col cols="10" class="content-details" v-html="template"></b-col>
    </b-row>
  </b-container>
</template>
<script>
import Content from '@/views/content/model'
import model from '@/views/model.mixin'

export default {
  name: 'gip.content.details',
  props: {
    value: Content
  },
  mixins: [model('value')]
}
</script>
