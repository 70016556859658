<template>
  <b-collapse tag="ul" :id="'category_'+uniq+parent.id" :visible="hasSelectedChild">
    <li v-for="category in linkCategories" :key="category.id" class="subcategory">
      <b-checkbox @change="toggle(category)" :checked="value.map(v => v.id).includes(category.id)">{{ category.name }}</b-checkbox>
    </li>
  </b-collapse>
</template>
<style lang="scss" scoped>
@import '@/assets/custom-vars.scss';
ul {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    font-size: 1em;
    cursor: pointer;
    margin-left: 1em;
  }
}
</style>
<script>
import Category from './model'

export default {
  name: 'gip.softskill.category.select.leaf',
  props: {
    value: Array,
    categories: Array,
    parent: Category,
    uniq: String
  },
  data () {
    return { linkCategories: this.categories }
  },
  methods: {
    toggle (category) {
      const at = this.value.findIndex(v => v.id === category.id)
      if (at >= 0) {
        this.value.splice(at, 1)
      } else {
        this.value.push(category)
      }
      return this.$emit('input', this.value)
    }
  },
  computed: {
    hasSelectedChild () {
      const ids = this.value.map(v => v.id)
      return this.categories.some(c => ids.includes(c.id))
    }
  }
}
</script>
