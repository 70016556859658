export function required (fields, value) {
  let found = fields.find(function (field) {
    return !(value.hasOwnProperty(field) && isValue(value[field]) && ((value[field].hasOwnProperty('length') && value[field].length > 0) || value[field].hasOwnProperty('id')))
  })
  return found ? 'Champ ' + found + ' requis' : null
}

export function checkValue (allowedValues, value) {
  if (allowedValues.includes(value)) {
    return null
  }
  return 'Valeur ' + value + ' invalide'
}

export function checkPattern (value, canBeNull, pattern = null, minLength = null) {
  if (canBeNull && !value) {
    return null
  }
  let isValid = false
  if (pattern) {
    isValid = pattern.test(value)
  }
  if (minLength) {
    isValid = (value.length >= minLength)
  }
  return isValid ? null : 'Valeur ' + value + ' invalide'
}

function isDef (value) {
  return typeof value !== 'undefined'
}
function isNull (value) {
  return value === null
}

function isValue (value) {
  return isDef(value) && !isNull(value)
}
