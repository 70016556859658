<template>
  <b-list-group>
    <template v-for="category in linkCategories">
      <b-list-group-item :key="category.id" class="root" v-if="isParent(category)">
        <div class="category-title">
          <b-checkbox @change="toggle(category)" :checked="value.map(v => v.id).includes(category.id)">{{ category.name }}</b-checkbox>
          <a href="#" v-b-toggle="'category_'+uniq+category.id" @click.prevent="" v-if="category.children.length > 0 && (depth === 0 || depth > 1)" class="category-toggle">🞂</a>
        </div>
        <select-leaf :uniq="uniq" :categories="category.children" v-model="value" :parent="category" v-if="depth === 0 || depth > 1"></select-leaf>
      </b-list-group-item>
    </template>
  </b-list-group>
</template>
<style lang="scss" scoped>
@import '@/assets/custom-vars.scss';
.list-group {
  padding: 0;
  margin: 0;
  .list-group-item {
    padding: 0.5em;
  }
  .list-group-item {
    border: none;
    color: $color-blue-dark;
    .category-title {
      border-bottom: 1px $color-blue-light solid;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &:last-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .subcategory {
    margin-left: 2em;
  }
  .category-toggle {
    font-size: 1.5em;
    margin-left: 0.5em;
    color: $color-blue-light-new;
    text-decoration: none;
    &[aria-expanded=true] {
      animation-name: gip-rotate;
      animation-duration: 0.4s;
      animation-fill-mode: both;
      transform-origin: center;
    }
    &:not([aria-expanded=true]) {
      animation-name: gip-rotate-reverse;
      animation-duration: 0.4s;
      animation-fill-mode: both;
      transform-origin: center;
    }
  }
}
@keyframes gip-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
@keyframes gip-rotate-reverse {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}</style>
<script>
import SelectLeaf from './select-leaf'
export default {
  name: 'gip.softskill.category.select',
  components: {
    SelectLeaf
  },
  props: {
    value: Array,
    categories: Array,
    depth: {
      type: Number,
      default: 0
    },
    uniq: {
      default () {
        return Math.random().toString(36).substr(2, 5)
      }
    },
    deny: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return { linkCategories: this.categories }
  },
  methods: {
    toggle (category) {
      const at = this.value.findIndex(v => v.id === category.id)
      if (at >= 0) {
        this.value.splice(at, 1)
      } else {
        this.value.push(category)
      }
      return this.$emit('input', this.value)
    },
    isCollapsed (category) {
      return this.value.findIndex(v => v.id === category.id) >= 0
    },
    isParent (cat) {
      return cat.parents.length === 0 && !this.deny.some(d => d.id === cat.id)
    }
  }
}
</script>
