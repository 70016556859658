<template>
  <b-form-select v-model="search" @change="() => $emit('input', value)" :options="options"></b-form-select>
</template>
<script>
import Error from '@/views/error/mixin'

export default {
  name: 'gip.selector.all',
  props: {
    field: {
      type: String,
      default: () => 'name'
    }
  },
  mixins: [Error],
  data () {
    return {
      options: [],
      search: this.value.id,
      results: []
    }
  },
  async mounted () {
    try {
      const opts = this.defaultOrders ? { orders: this.defaultOrders } : {}
      opts.limit = 100
      this.loading = this.type.search(opts)
      const { results, count } = await this.loading
      this.count = count
      this.results.splice(0, this.results.length, ...results)
      this.options.splice(0, this.options.length, ...this.results.map(result => {
        return {
          'value': result.id,
          'text': result[this.field]
        }
      }))
    } catch (err) {
      this.handleError(err)
    }
    this.loading = false
  },
  watch: {
    value () {
      this.search = this.value.id
    },
    async search (val) {
      this.value.setData(this.results.find(r => r.id === this.search))
    }
  }
}
</script>
