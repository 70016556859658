<template>
  <div style="text-justify: center;">
    <b-form class="justify-content-between">
      <b-row>
        <b-col cols="8">
          <b-form-group label="Langue*">
            <language-select v-model="language" ref="language" @input="select"/>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Score*">
            <galaxy v-model="utility" writable @change="$emit('change', value)" class="text-center"/>
          </b-form-group>
        </b-col>
        <b-col>
          <slot name="actions"></slot>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<script>
import VisitTradeLanguage from '@/views/visit_trade_language/model'
import LanguageSelect from '@/views/language/select'
import Galaxy from '@/views/utility/galaxy'
import model from '@/views/model.mixin'
import Language from '@/views/language/model'
import pick from 'ramda/src/pick'
import utilities from '@/views/utility/cache'

export default {
  name: 'gip.visit.trade.language.form',
  props: {
    value: VisitTradeLanguage
  },
  components: { LanguageSelect, Galaxy },
  mixins: [model('value')],
  methods: {
    reset () {
      this.language.removeListener()
      this.utility.removeListener()
      this.language = new Language()
      this.value.setData({ utility: utilities.list[0].clone() })
      this.$refs.language.reset()
    },
    select (language) {
      this.value.setData({ language })
      this.$emit('change', this.value)
    },
    onSubmit () {
      this.value.setData(pick(this.value.properties, this))
    }
  }
}
</script>
