import { handleError, handlePromiseError, onAgreementError, notifyError } from '@/views/error/handlers'
export default function (model, itemField) {
  return {
    props: {
      page: {
        type: Number,
        default: 1
      },
      limit: {
        type: Number,
        default: 5
      }
    },
    data () {
      return {
        count: 0,
        loading: false,
        orders: [],
        serializers: []
      }
    },
    async mounted () {
      this.search()
    },
    watch: {
      filters () {
        this.page = 1
        this.search()
      }
    },
    methods: {
      notifyError,
      handleError,
      handlePromiseError,
      onAgreementError,
      async toPage (page) {
        this.search({ page })
      },
      request (opts) {
        return model.search(Object.assign(
          {
            page: this.page,
            limit: this.limit,
            filters: this.filters,
            orders: this.orders.length > 0 ? this.orders : (this.defaultOrders || []),
            serializers: this.serializers || []
          },
          opts
        ))
      },
      async search (opts = {}) {
        try {
          // @todo throttling and cancel request
          this.loading = this.request(opts)
          const { results, count } = await this.loading
          this.count = count
          this[itemField].splice(0, this[itemField].length, ...results)
        } catch (err) {
          this.handleError(err)
        }
        this.loading = false
      },
      onSorting (ctx) {
        if (ctx.sortBy) {
          this.page = 1
          if (ctx.sortBy === 'lastVisitDate') {
            ctx.sortBy = '#v.date'
          }
          this.orders.splice(0, this.orders.length, {
            column: ctx.sortBy,
            direction: ctx.sortDesc ? 'DESC' : 'ASC'
          })
          this.search()
        }
      }
    }
  }
}
