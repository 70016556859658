var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.state.loading)?_c('b-spinner',{attrs:{"variant":"primary"}}):_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('b-card',{staticClass:"visit-trade-skills_title"},[_c('span',{staticClass:"visit-trade-skills_media"},[_c('unicon',{attrs:{"name":"suitcase","fill":"white"}}),_c('span',[_vm._v("Métier")])],1),_c('h3',[_vm._v(_vm._s(_vm.trade.name))])]),_c('header',{staticClass:"bloc-title"},[_c('h1',{staticClass:"bloc-title-content"},[_vm._v("Compétences ajoutées")])]),_c('visit-trade-skill-form',{ref:"skill",staticClass:"mt-5",attrs:{"trade":_vm.trade},on:{"change":_vm.onSkillUpdate,"text":_vm.onSkillUpdate},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',[_c('b-button',{attrs:{"variant":"primary","disabled":!_vm.isAddSkillUnable},on:{"click":_vm.onAddSkill}},[_c('unicon',{attrs:{"name":"plus"}})],1),_c('b-modal',{ref:"confirm",attrs:{"title":"Ajouter la compétence","centered":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{on:{"click":cancel}},[_vm._v("Annuler")]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isSavingSkill},on:{"click":function () { return _vm.addSkill(ok); }}},[_vm._v("Ajouter")])]}}])},[_c('p',{staticClass:"my-4"},[_vm._v("Cette compétence n'existe pas dans notre référentiel, souhaitez vous l'ajouter ?")])])],1)]},proxy:true}]),model:{value:(_vm.visitTradeSkill),callback:function ($$v) {_vm.visitTradeSkill=$$v},expression:"visitTradeSkill"}}),_c('skill-table',{attrs:{"skills":_vm.pimpeds,"columns":_vm.baseFields},scopedSlots:_vm._u([{key:"utility",fn:function(ref){
var skill = ref.skill;
return [_c('galaxy',{attrs:{"writable":""},model:{value:(skill.utility),callback:function ($$v) {_vm.$set(skill, "utility", $$v)},expression:"skill.utility"}})]}}])}),_c('header',{staticClass:"bloc-title"},[_c('h1',{staticClass:"bloc-title-content"},[_vm._v("Compétences")])]),_c('skill-table',{attrs:{"skills":_vm.specifics,"columns":_vm.baseFields},scopedSlots:_vm._u([{key:"utility",fn:function(ref){
var skill = ref.skill;
return [_c('galaxy',{attrs:{"writable":"","banable":""},model:{value:(skill.utility),callback:function ($$v) {_vm.$set(skill, "utility", $$v)},expression:"skill.utility"}})]}},{key:"ecological",fn:function(ref){
var skill = ref.skill;
return [_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(skill.ecological),callback:function ($$v) {_vm.$set(skill, "ecological", $$v)},expression:"skill.ecological"}})]}},{key:"numerical",fn:function(ref){
var skill = ref.skill;
return [_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(skill.numerical),callback:function ($$v) {_vm.$set(skill, "numerical", $$v)},expression:"skill.numerical"}})]}},{key:"technological",fn:function(ref){
var skill = ref.skill;
return [_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(skill.technological),callback:function ($$v) {_vm.$set(skill, "technological", $$v)},expression:"skill.technological"}})]}}])}),_vm._t("actions",[_c('b-button',{staticClass:"float-right",attrs:{"type":"submit","variant":"primary","disabled":_vm.state.saving}},[(_vm.state.saving)?_c('b-spinner',{attrs:{"variant":"light","type":"grow","small":""}}):_vm._e(),_vm._v(" Enregistrer ")],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }