import Model from '@/views/model'
import Utility from '@/views/utility/model'
import SoftSkill from '@/views/softskill/model'

const schema = {
  collections: {},
  includes: {
    utility: Utility,
    softSkill: SoftSkill
  }
}

class VisitTradeSoftSkill extends Model {
  async load () {
    return super.load()
      .then(() => {
        this.setData({
          softSkill: {},
          utility: {}
        })
        return Promise.resolve(this)
      })
  }
  static search (options) {
    return super.search(options, VisitTradeSoftSkill)
  }
}

VisitTradeSoftSkill.prototype.properties = Model.prototype.properties.concat([
  'softSkill',
  'utility'
])
VisitTradeSoftSkill.prototype.schema = schema

export default VisitTradeSoftSkill
