import diacritics from 'diacritics'
const vowels = 'aeiou'

function startVowel (text) {
  return vowels.includes(diacritics.remove(text.trim()[0]).toLowerCase())
}

function translate (raw) {
  try {
    let fields = JSON.parse(raw)
    return Object.entries(fields).map(([f, r]) => read(f, r)).join('/n')
  } catch (err) {
    return raw
  }
}

function read (field, reason) {
  let r = reasons[reason]
  if (r) {
    return r(readField(field))
  }
  return reason
}

function readField (field) {
  let k = field.split('.').pop()
  if (k && k in keys) {
    return keys[k]
  }
  if (k) {
    return { text: k, feminine: false }
  }
  return { text: field, feminine: false }
}

const keys = {
  email: {
    text: 'email', feminine: false
  },
  phone: {
    text: 'numéro de téléphone', feminine: false
  },
  quantity: {
    text: 'quantité', feminine: true
  },
  mission: {
    text: 'mission', feminine: true
  },
  command: {
    text: 'commande', feminine: true
  }

}

function genderCette (k) {
  return k.feminine ? 'cette' : 'cet'
}

function genderLa (k) {
  return startVowel(k.text) ? 'l\'' : (k.feminine ? 'la' : 'le')
}

function capitalize (text) {
  return text[0].toUpperCase() + text.substring(1)
}

const reasons = {
  'This value is already used.': k => `${capitalize(genderCette(k))} ${k.text} est déjà utilisé${k.feminine ? 'e' : ''}.`,
  'This value should not be blank.': k => `${capitalize(genderLa(k))} ${k.text} doit être renseigné.`,
  'This value should not be null.': k => `${capitalize(genderLa(k))} ${k.text} doit être renseigné.`,
  'This value is not valid.': k => `${capitalize(genderCette(k))} ${k.text} est incorrect${k.feminine ? 'e' : ''}.`,
  'This value should be greater than 0.': k => `${capitalize(genderLa(k))} ${k.text} doit être plus grand${k.feminine ? 'e' : ''} que 0.`
}

export {
  translate, read, readField
}
