import Model from '@/views/model'

class Skill extends Model {
  static search (options) {
    return super.search(options, Skill)
  }
  clone () {
    return new Skill(this.marshall())
  }
  async merge (target) {
    let response = null
    try {
      response = Model.api.then(api => api.get(['skill', this.id, 'merge', target.id].join('/')))
      this.setState('saving', response)
      let done = await response
      this.setData(done.data)
      this.setState('saving', false)
    } catch (err) {
      this.setState('saving', false)
      throw response
    }
    return Promise.resolve(this)
  }
}

Skill.prototype.properties = Model.prototype.properties.concat(['name', 'reviewed'])
Skill.prototype.baseUrl = '/crud/skill'

export default Skill
