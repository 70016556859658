<template>
  <b-card :title="name">
    <b-link class="detail-link" :to="'/entreprise/'+id" v-if="link">
      <unicon name="eye" />
    </b-link>
    <span class="visit-diff in-card recent" v-if="lastVisitDate <= 3" title="Il y a moins de 3 mois"></span>
    <span class="visit-diff in-card middle" v-if="lastVisitDate <= 6 && lastVisitDate > 3" title="Il y a moins de 6 mois"></span>
    <span class="visit-diff in-card old" v-if="lastVisitDate > 6" title="Il y a plus de 6 mois"></span>
    <b-card-text>
      <b-row>
        <b-col>
          <ul>
            <li>{{ siret }}</li>
            <li>{{ naf.name }}</li>
            <li>{{ address }}</li>
            <li>{{ cityPostcode.postcode.name }} {{ cityPostcode.city.name }}</li>
          </ul>
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>
<script>
import Siret from '@/views/siret/model'
import Visit from '../visit/model'
import model from '@/views/model.mixin'
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths'

export default {
  name: 'gip.siret.card',
  props: {
    value: Siret,
    link: Boolean,
    lastVisitDate: Number
  },
  mixins: [model('value')],
  async mounted () {
    this.getLastVisitDate()
  },
  methods: {
    async getLastVisitDate () {
      const loading = Visit.search({
        page: 1,
        limit: 1,
        filters: [
          {
            type: '\\App\\Model\\Filter\\EqualsFilter',
            field: 'siret',
            value: JSON.stringify(this.id)
          }
        ],
        orders: [{
          column: 'date',
          direction: 'DESC'
        }]
      })
      const result = await loading
      if (result.results.length > 0) {
        this.lastVisitDate = differenceInCalendarMonths(new Date(), new Date(result.results[0].date))
      } else {
        this.lastVisitDate = 10
      }
    }
  }
}
</script>
