<template>
  <div class="gip_stepper">
    <b-row class="mb-3 justify-content-center">

      <b-col cols="1" xl="1">
        <b-btn :class="{ stepper_step: true, stepper_selected: step === 1 }" pill variant="outline-primary" @click="step = 1">1</b-btn>
      </b-col>

      <b-col cols="1" xl="1" class="stepper_step_separator"/>

      <b-col cols="1" xl="1">
        <b-btn :class="{ stepper_step: true, stepper_selected: step === 2 }" pill variant="outline-primary" :disabled="!id" @click="step = 2">2</b-btn>
      </b-col>

      <b-col cols="1" xl="1" class="stepper_step_separator"/>

      <b-col cols="1" xl="1">
        <b-btn :class="{ stepper_step: true, stepper_selected: step === 3 }" pill variant="outline-primary" :disabled="!id" @click="step = 3">3</b-btn>
      </b-col>

      <b-col cols="1" xl="1" class="stepper_step_separator"/>

      <b-col cols="1" xl="1">
        <b-btn :class="{ stepper_step: true, stepper_selected: step === 4 }" pill variant="outline-primary" :disabled="!id" @click="step = 4">4</b-btn>
      </b-col>

      <b-col cols="1" xl="1" class="stepper_step_separator"/>

      <b-col cols="1" xl="1">
        <b-btn :class="{ stepper_step: true, stepper_selected: step === 5 }" pill variant="outline-primary" :disabled="!id" @click="step = 5">5</b-btn>
      </b-col>

    </b-row>

    <visit-trade-edit v-if="step === 1" v-model="value" @done="() => toStep(2)">
      <template v-slot:actions="{ isValid }">
        <b-button type="submit" variant="primary" :disabled="!isValid || state.saving">
          <b-spinner v-if="state.saving" variant="light" type="grow" small/>
          Suivant
        </b-button>
      </template>
    </visit-trade-edit>

    <visit-trade-skills-score v-if="step === 2" v-model="value" @done="this.$refs.wannaSpecialSkill.show">
      <template v-slot:actions>
        <b-button type="submit" variant="primary" class="float-right" :disabled="state.saving">
          <b-spinner v-if="state.saving" variant="light" type="grow" small/>
          Suivant
        </b-button>
      </template>
    </visit-trade-skills-score>

    <b-modal ref="wannaSpecialSkill" title="Compétences métiers" centered no-close-on-backdrop no-close-on-esc hide-header-close>
      <p class="my-4">Pensez-vous que certaines de ces compétences seront impactées par une évolution numérique, écologique ou technologique ?</p>
      <template #modal-footer="{ ok }">
        <b-button @click="toStep(4, ok)">Non</b-button>
        <b-button variant="primary" @click="toStep(3, ok)">Oui</b-button>
      </template>
    </b-modal>

    <visit-trade-skills-special v-if="step === 3" v-model="value" @done="() => toStep(4)">
      <template v-slot:actions>
        <b-button type="submit" variant="primary" class="float-right" :disabled="state.saving">
          <b-spinner v-if="state.saving" variant="light" type="grow" small/>
          Suivant
        </b-button>
      </template>
    </visit-trade-skills-special>

    <b-form v-if="step === 4" @submit.prevent="saveSoftSkill">

      <b-card class="visit-trade-skills_title">
        <span class="visit-trade-skills_media">
          <unicon name="suitcase" fill="white"/>
          <span>Métier</span>
        </span>
        <h3>{{ trade.name }}</h3>
      </b-card>

      <visit-trade-softskills v-model="value" ref="softskills"/>

      <b-button type="submit" variant="primary" class="float-right mt-3">
        <b-spinner v-if="state.saving" variant="light" type="grow" small/>
          Enregistrer
      </b-button>

    </b-form>

    <b-form v-if="step === 5" @submit.prevent="done">

      <b-card class="visit-trade-skills_title">
        <span class="visit-trade-skills_media">
          <unicon name="suitcase" fill="white"/>
          <span>Métier</span>
        </span>
        <h3>{{ trade.name }}</h3>
      </b-card>

      <visit-trade-languages v-model="value" ref="languages"/>

      <visit-trade-work-conditions v-model="value" ref="workconditions"/>

      <b-button type="submit" variant="primary" class="float-right mt-3">
        <b-spinner v-if="state.saving" variant="light" type="grow" small/>
          Enregistrer
      </b-button>

    </b-form>
  </div>
</template>
<script>
import model from '@/views/model.mixin'
import VisitTrade from '@/views/visit_trade/model'
import VisitTradeEdit from '@/views/visit_trade/form'
import VisitTradeSkillsScore from '@/views/visit_trade_skill/skills_score_form'
import VisitTradeSkillsSpecial from '@/views/visit_trade_skill/skills_special_form'
import VisitTradeLanguages from '@/views/visit_trade_language/languages_form'
import VisitTradeSoftskills from '@/views/visit_trade_softskill/softskills_form'
import VisitTradeWorkConditions from '@/views/work_condition/conditions_form'

export default {
  name: 'gip.visit.trade.stepper',
  props: {
    value: VisitTrade
  },
  components: {
    VisitTradeEdit,
    VisitTradeSkillsScore,
    VisitTradeSkillsSpecial,
    VisitTradeLanguages,
    VisitTradeSoftskills,
    VisitTradeWorkConditions
  },
  mixins: [model('value')],
  data () {
    return {
      step: 1
    }
  },
  methods: {
    toStep (step, cb) {
      this.step = step
      if (cb && typeof cb === 'function') {
        cb()
      }
    },
    async saveSoftSkill () {
      await this.$refs.softskills.save()
      this.toStep(5)
    },
    done () {
      Promise.all([
        this.$refs.languages.save(),
        this.$refs.workconditions.save()
      ]).then(() => {
        this.$router.push({
          name: 'visit_details',
          params: { id: this.value.visit.id }
        })
      })
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/custom-vars.scss';
.gip_stepper {
  .stepper_step {
    width: 2em;
    height: 2em;
    line-height: 1.2em;
    text-align: center;
    border: 1px $color-blue-new solid;
    color: $color-blue-new;
    margin: auto;
    display: block;
    padding: 0;
    &.stepper_selected {
      font-weight: 900;
    }
    &.stepper_selected, &:hover {
      color: $color-white;
      background: $color-blue-new;
    }
  }
  .stepper_step_separator {
    background: $color-blue-new;
    overflow: visible;
    height: 1px;
    margin: auto 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .col-1 {
    padding: 0;
  }
}
</style>
