import Model from '@/views/model'
import Skill from '@/views/skill/model'

class Trade extends Model {
  static search (options) {
    return super.search(options, Trade)
  }
}

Trade.prototype.properties = Model.prototype.properties.concat(['name', 'ogr', 'skills', 'shortName', 'rome'])
Trade.prototype.schema = { collections: { skills: Skill }, includes: {} }
Trade.prototype.baseUrl = '/crud/trade'

export default Trade
