<template>
  <div>
    <template v-if="sirets.length || loading">
      <b-table responsive striped :items="sirets" :fields="fields" class="darker" :busy="Boolean(loading)" no-local-sorting @sort-changed="onSorting">
        <template v-slot:cell(actions)="row">
          <slot name="actions" :siret="row.item">
            <b-button-group>
              <b-button :to="{ name: 'siret_details', params: { id: row.item.id } }" variant="primary" size="sm">
                <unicon name="eye"></unicon>
              </b-button>
              <b-button :to="{ name: 'siret_edit', params: { id: row.item.id } }" variant="primary" size="sm" v-if="canEdit(row.item)">
                <unicon name="pen"></unicon>
              </b-button>
            </b-button-group>
          </slot>
        </template>
        <template v-slot:cell(lastVisitDate)="row">
          <template v-if="visits.length > 0">
            <span class="visit-diff recent" v-if="lastVisitDate(row.item.id) <= 3" title="Il y a moins de 3 mois"></span>
            <span class="visit-diff middle" v-if="lastVisitDate(row.item.id) <= 6 && lastVisitDate(row.item.id) > 3" title="Il y a moins de 6 mois"></span>
            <span class="visit-diff old" v-if="lastVisitDate(row.item.id) > 6" title="Il y a plus de 6 mois"></span>
          </template>
        </template>
        <template #table-busy>
          <div class="text-center" text-primary>
            <b-spinner v-if="loading" class="align-middle"/>
          </div>
        </template>
      </b-table>
      <b-pagination
        pills
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        aria-controls="my-table"
        @input="toPage"
      ></b-pagination>
    </template>
    <b-alert  v-if="!loading && sirets.length === 0" show>Aucune entreprise à afficher.</b-alert>
  </div>
</template>
<script>
import Siret from './model'
import Visit from '../visit/model'
import table from '@/views/table.mixin'
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths'

export default {
  name: 'gip.visit.table',
  mixins: [table(Siret, 'sirets')],
  props: {
    fields: {
      type: Array,
      default: () => [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Entreprise', sortable: true },
        { key: 'siret', label: 'Siret', sortable: true },
        { key: 'actions', label: '' }
      ]
    },
    filters: Array,
    defaultOrders: {
      type: Array,
      default: () => {
        return [{
          column: 'name',
          direction: 'ASC'
        }]
      }
    }
  },
  data () {
    return {
      sirets: [],
      visits: [],
      visitFields: [
        { key: 'date', label: 'Date', sortable: true },
        { key: 'user', label: 'Chargé de la visite', sortable: true },
        { key: 'purpose', label: 'Objet de la visite', sortable: true },
        { key: 'siret', label: 'Entreprise', sortable: true },
        { key: 'actions', label: '' }
      ],
      serializers: ['short', 'default', 'zone']
    }
  },
  watch: {
    sirets: function (val) {
      if (this.fields.some(f => f.key === 'lastVisitDate')) {
        val.forEach(siret => {
          this.getLastVisitDate(siret).then((result) => {
            this.visits.push(result)
          })
        })
      }
    }
  },
  methods: {
    canEdit (siret) {
      return this.$user.roles.includes('ROLE_ADMIN')
    },
    async getLastVisitDate (siret) {
      const loading = Visit.search({
        page: 1,
        limit: 1,
        filters: [
          {
            type: '\\App\\Model\\Filter\\EqualsFilter',
            field: 'siret',
            value: JSON.stringify(siret.id)
          }
        ],
        orders: [{
          column: 'date',
          direction: 'DESC'
        }],
        serializers: ['id', 'date']
      })
      const result = await loading
      return result.results[0]
    },
    lastVisitDate (siretId) {
      const lastVisit = this.visits.find(v => v.siret.id === siretId)
      if (lastVisit) {
        return differenceInCalendarMonths(new Date(), new Date(lastVisit.date))
      }
      return 10
    }
  }
}
</script>
