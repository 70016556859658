<template>
  <div>
    <template v-if="visits.length || loading">
      <b-table responsive striped :items="visits" :fields="filteredVisitFields" class="darker" :busy="Boolean(loading)" no-local-sorting @sort-changed="onSorting">
        <template v-slot:cell(user)="row" v-if="fields.includes('user')">
          <slot name="user" v-bind="row.item.user">
            <b-link :to="'/user/'+row.item.user.id">{{ row.item.user.firstname }} {{ row.item.user.lastname }}</b-link>
          </slot>
        </template>
        <template v-slot:cell(date)="row" v-if="fields.includes('date')">
          {{ visitDate(row.item.date) }}
        </template>
        <template v-slot:cell(purposes)="row" v-if="fields.includes('purposes')">
          {{ row.item.purposes.map(p => p.label).join(', ') }}
        </template>
        <template v-slot:cell(employmentZone)="row" v-if="fields.includes('employmentZone')">
          {{ row.item.siret.cityPostcode.city.employmentZone.name }}
        </template>
        <template v-slot:cell(siret)="row" v-if="fields.includes('siret')">
          <b-link :to="'/entreprise/'+row.item.siret.id">{{ row.item.siret.name }}</b-link>
        </template>
        <template v-slot:cell(trade)="row" v-if="fields.includes('trade')">
          <span v-if="row.item.trades.length > 0">{{ row.item.trades[0].trade.name }}</span>
          <tips v-if="row.item.trades.length > 1" :title="row.item.trades.map(t => t.trade.name).join(', ')"/>
        </template>
        <template v-slot:cell(lastVisitDate)="row">
          <template v-if="visits.length > 0">
            <span class="visit-diff recent" v-if="lastVisitDate(row.item.siret.id) <= 3" title="Il y a moins de 3 mois"></span>
            <span class="visit-diff middle" v-if="lastVisitDate(row.item.siret.id) <= 6 && lastVisitDate(row.item.siret.id) > 3" title="Il y a moins de 6 mois"></span>
            <span class="visit-diff old" v-if="lastVisitDate(row.item.siret.id) > 6" title="Il y a plus de 6 mois"></span>
          </template>
        </template>
        <template v-slot:cell(actions)="row">
          <slot name="actions" :visit="row.item">
            <b-button-group>
              <b-button variant="primary btn-sm" class="detail-link" :to="'/visite/'+row.item.id" v-if="canRead(row.item)">
                <unicon name="eye" />
              </b-button>
              <b-button variant="primary btn-sm" class="detail-link" disabled title="L'entreprise n'a pas donné son accord afin de partager le détail de cette visite" v-else>
                <unicon name="eye-slash" />
              </b-button>
              <!-- <b-button variant="primary btn-sm" v-if="canEdit(row.item)" class="detail-link" :to="`/visit/${row.item.id}/duplicate`">
                <unicon name="copy" />
              </b-button> -->
              <b-button variant="primary btn-sm" v-if="canEdit(row.item)" class="detail-link" :to="`/visit/${row.item.id}/edit`">
                <unicon name="pen" />
              </b-button>
            </b-button-group>
          </slot>
        </template>
        <template #table-busy>
          <div class="text-center" text-primary>
            <b-spinner v-if="loading" class="align-middle"/>
          </div>
        </template>
      </b-table>
      <b-pagination
        pills
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        aria-controls="my-table"
        @input="toPage"
      ></b-pagination>
    </template>
    <b-alert  v-if="!loading && visits.length === 0" show>Aucune visite à afficher.</b-alert>
  </div>
</template>
<script>
import format from 'date-fns/format'
import Visit from './model'
import table from '@/views/table.mixin'
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths'
import Tips from '@/views/tips'

export default {
  name: 'gip.visit.table',
  mixins: [table(Visit, 'visits')],
  props: {
    fields: {
      type: Array,
      default: () => ['date', 'user', 'purposes', 'actions']
    },
    filters: Array,
    defaultOrders: {
      type: Array,
      default: () => {
        return [{
          column: 'date',
          direction: 'DESC'
        }]
      }
    }
  },
  components: {
    Tips
  },
  data () {
    return {
      visits: [],
      visitDates: [],
      visitFields: [
        { key: 'date', label: 'Date', sortable: true },
        { key: 'user', label: 'Chargé de la visite', sortable: true },
        { key: 'purposes', label: 'Objets de la visite' },
        { key: 'siret', label: 'Entreprise', sortable: true },
        { key: 'siret.cityPostcode.city.employmentZone.name', label: 'Territoire', sortable: true },
        { key: 'actions', label: '' },
        { key: 'trade', label: 'Métier' },
        { key: 'lastVisitDate', label: 'Dernière visite', sortable: false, thClass: 'text-center' }
      ],
      serializers: ['short']
    }
  },
  watch: {
    visits: function (val) {
      if (this.fields.some(f => f === 'lastVisitDate')) {
        val.forEach(visit => {
          this.getLastVisitDate(visit.siret).then((result) => {
            this.visitDates.push(result)
          })
        })
      }
    }
  },
  methods: {
    visitDate (date) {
      return format(new Date(date), 'dd/MM/yyyy')
    },
    async getLastVisitDate (siret) {
      const loading = Visit.search({
        page: 1,
        limit: 1,
        filters: [
          {
            type: '\\App\\Model\\Filter\\EqualsFilter',
            field: 'siret',
            value: JSON.stringify(siret.id)
          }
        ],
        orders: [{
          column: 'date',
          direction: 'DESC'
        }],
        serializers: ['date', 'id']
      })
      const result = await loading
      return result.results[0]
    },
    lastVisitDate (siretId) {
      const lastVisit = this.visitDates.find(v => v.siret.id === siretId)
      if (lastVisit) {
        return differenceInCalendarMonths(new Date(), new Date(lastVisit.date))
      }
      return 10
    },
    canEdit (visit) {
      return this.$user.roles.includes('ROLE_ADMIN') || visit.user.id === this.$user.id
    },
    canRead (visit) {
      return this.$user.roles.includes('ROLE_ADMIN') || visit.user.id === this.$user.id || visit.consent
    }
  },
  computed: {
    filteredVisitFields () {
      return this.fields.map(f => this.visitFields.find(i => i.key === f))
    }
  }
}
</script>
