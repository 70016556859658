import Model from '@/views/model'

class Postcode extends Model {
  static search (options) {
    return super.search(options, Postcode)
  }
}

Postcode.prototype.properties = Model.prototype.properties.concat(['code', 'name', 'lat', 'lng'])
Postcode.prototype.baseUrl = '/crud/postcode'

export default Postcode
