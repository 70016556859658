import Model from '@/views/model'

class Mission extends Model {
  static search (options) {
    return super.search(options, Mission)
  }
}

Mission.prototype.schema = {
  collections: {},
  includes: {}
}
Mission.prototype.properties = Model.prototype.properties.concat(['name'])
Mission.prototype.baseUrl = '/crud/mission'

export default Mission
