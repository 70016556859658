var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.state.loading)?_c('b-spinner',{attrs:{"variant":"primary"}}):_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('b-card',{staticClass:"visit-trade-skills_title"},[_c('span',{staticClass:"visit-trade-skills_media"},[_c('unicon',{attrs:{"name":"suitcase","fill":"white"}}),_c('span',[_vm._v("Métier")])],1),_c('h3',[_vm._v(_vm._s(_vm.trade.name))])]),_c('header',{staticClass:"bloc-title"},[_c('h1',{staticClass:"bloc-title-content"},[_vm._v("Compétences ajoutées")])]),_c('skill-table',{attrs:{"skills":_vm.pimpeds,"columns":_vm.baseFields},scopedSlots:_vm._u([{key:"ecological",fn:function(ref){
var skill = ref.skill;
return [_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(skill.ecological),callback:function ($$v) {_vm.$set(skill, "ecological", $$v)},expression:"skill.ecological"}})]}},{key:"numerical",fn:function(ref){
var skill = ref.skill;
return [_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(skill.numerical),callback:function ($$v) {_vm.$set(skill, "numerical", $$v)},expression:"skill.numerical"}})]}},{key:"technological",fn:function(ref){
var skill = ref.skill;
return [_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(skill.technological),callback:function ($$v) {_vm.$set(skill, "technological", $$v)},expression:"skill.technological"}})]}}])}),_c('header',{staticClass:"bloc-title"},[_c('h1',{staticClass:"bloc-title-content"},[_vm._v("Compétences")])]),_c('skill-table',{attrs:{"skills":_vm.specifics,"columns":_vm.baseFields},scopedSlots:_vm._u([{key:"ecological",fn:function(ref){
var skill = ref.skill;
return [_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(skill.ecological),callback:function ($$v) {_vm.$set(skill, "ecological", $$v)},expression:"skill.ecological"}})]}},{key:"numerical",fn:function(ref){
var skill = ref.skill;
return [_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(skill.numerical),callback:function ($$v) {_vm.$set(skill, "numerical", $$v)},expression:"skill.numerical"}})]}},{key:"technological",fn:function(ref){
var skill = ref.skill;
return [_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(skill.technological),callback:function ($$v) {_vm.$set(skill, "technological", $$v)},expression:"skill.technological"}})]}}])}),_vm._t("actions",[_c('b-button',{staticClass:"float-right",attrs:{"type":"submit","variant":"primary"}},[(_vm.state.saving)?_c('b-spinner',{attrs:{"variant":"light","type":"grow","small":""}}):_vm._e(),_vm._v(" Enregistrer ")],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }