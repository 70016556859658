<script>
import Language from './model'
import Selector from '@/views/selector'

export default {
  name: 'gip.language.selector',
  extends: Selector,
  props: {
    value: Language,
    type: {
      default: () => Language
    }
  }
}
</script>
