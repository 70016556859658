export const identity = { fn: item => item.id }
export const identityNum = { fn: item => Number(item.id) }
export function sortBy (field, direction = 1) {
  if (!field) {
    return () => 0
  }
  return function (a, b) {
    a = field in a ? a[field].toLowerCase() : null
    b = field in b ? b[field].toLowerCase() : null
    if (a < b) {
      return 1 * direction
    }
    if (a > b) {
      return -1 * direction
    }
    return 0
  }
}
