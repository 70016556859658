<template>
  <div>
    <b-table responsive striped :items="visitTrades" :fields="fields" class="darker" :busy="Boolean(busy)"  :tbody-transition-props="transProps" primary-key="id">
      <template v-slot:cell(trade)="row">
        {{ row.item.trade.name }}
      </template>
      <template v-slot:cell(deadline)="row">
        {{ row.item.deadline.label }}
      </template>
      <template v-slot:cell(quantity)="row">
        {{ row.item.quantity }}
      </template>
      <template v-slot:cell(recruitmentNeeded)="row">
        {{ row.item.recruitmentNeeded ? 'Oui' : 'Non' }}
      </template>
      <template v-slot:cell(done)="row">
        {{ isComplete(row.item.skills) ? 'Renseignées' : 'Incompletes' }}
      </template>
      <template v-slot:cell(jobType)="row">
        {{ jobTypeText(row.item) }}
      </template>
      <template v-slot:cell(actions)="row">
        <b-button-group>
          <b-button v-b-modal="'modal-skills' + row.item.id" variant="primary">
            <unicon name="eye"/>
          </b-button>
          <b-button :to="{ name: 'visit_trade_edit', params: { visitId: row.item.visit, id: row.item.id } }" variant="primary" v-if="visit.canEdit($user)">
            <unicon name="pen"/>
          </b-button>
          <b-button @click="openExport(row.item.id)" variant="primary" :disabled="Boolean(csv)">
            <unicon name="file-download"/>
          </b-button>
          <b-button @click="removeTrade(row.item)" variant="primary" v-if="visit.canEdit($user)">
            <unicon name="trash-alt"/>
          </b-button>
          <b-modal title="Configurer l'export" centered :ref="'export_' + row.item.id">
            <b-form @submit.prevent="onCSV(row.item)">
              <b-form-group>
                <b-form-radio-group
                  v-model="exportType"
                  :options="[{ text: 'Excel', value: 'xlsx' }, { text: 'PDF', value: 'pdf' }]"
                  buttons
                  name="radios-btn-warning"
                  button-variant="outline-primary"
                ></b-form-radio-group>
              </b-form-group>
              <b-form-row>
                <b-col>
                  <b-form-group label="Envoyer par email aux adresses suivantes (séparées par une virgule) :">
                    <b-input v-model="exportMail" />
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-form>
            <template #modal-footer="{ ok, cancel }">
              <b-button @click="cancel">Annuler</b-button>
              <b-button @click="onCSV(row.item)" variant="primary">Valider</b-button>
            </template>
          </b-modal>
        </b-button-group>
        <b-modal :id="'modal-skills' + row.item.id" size="xl" title="Compétences" scrollable cancel-disabled>
          <visit-trade-details v-model="row.item"/>
            <template v-slot:modal-footer="{close}">
              <div class="w-100">
                <b-button
                  variant="primary"
                  class="float-right"
                  @click="close">
                  Retour
                </b-button>
              </div>
            </template>
        </b-modal>
      </template>
      <template #table-busy>
        <div class="text-center" text-primary>
          <b-spinner class="align-middle"/>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import Visit from '@/views/visit/model'
import VisitTrade from '@/views/visit_trade/model'
import VisitTradeDetails from '@/views/visit_trade/details'

export default {
  name: 'gip.visit.trade.table',
  props: {
    busy: [Boolean, Promise],
    visitTrades: Array,
    visit: Visit
  },
  components: {
    VisitTradeDetails
  },
  data () {
    return {
      fields: [
        { key: 'trade', label: 'Métier', sortable: true },
        { key: 'recruitmentNeeded', label: 'Besoin de recrutement' },
        { key: 'actions', label: '' }
      ],
      transProps: {
        name: 'flip-list'
      },
      csv: false,
      exportType: 'xlsx',
      exportMail: ''
    }
  },
  methods: {
    async onCSV (visitTrade) {
      this.csv = true
      await VisitTrade.exportExcel(visitTrade.id, this.exportType, this.exportMail)
      this.csv = false
      this.$refs['export_' + visitTrade.id].hide()
      this.exportType = 'xlsx'
      this.exportMail = ''
      this.$notify({
        content: 'Votre export est en cours, vous serez notifié dès qu\'il sera disponible.',
        title: 'Export'
      })
    },
    openExport (visitTradeId) {
      this.$refs['export_' + visitTradeId].show()
    },
    isComplete (skills) {
      return skills.some(s => Number(s.utility.score) === 0)
    },
    async removeTrade (trade) {
      try {
        await trade.delete()
        this.$notify({
          content: `${trade.trade.name} a été retiré`,
          title: 'Visite'
        })
        this.visitTrades.splice(this.visitTrades.indexOf(trade), 1)
      } catch (err) {
        this.$notify({
          content: `${trade.trade.name} n'a pu être retiré`,
          title: 'Visite'
        })
      }
    },
    jobTypeText (visitTrade) {
      const text = [
        visitTrade.newJob && 'création de poste',
        visitTrade.companyReorganization && 'réorganisation de l\'entreprise',
        visitTrade.renewJob && 'vacance de poste'
      ].filter(t => t).join(', ')
      return text.length ? text : '-'
    }
  }
}
</script>
<style>
.flip-list-move {
  transition: transform 0.4s;
}
</style>
