<template>
  <div>
    <template v-if="users.length || loading">
      <b-table responsive striped :items="users" :busy="Boolean(loading)" :fields="fields" no-local-sorting @sort-changed="onSorting">
        <template v-slot:cell(email)="row">
          <a :href="'mailto:'+row.item.email">{{ row.item.email }}</a>
        </template>
        <template v-slot:cell(actions)="row">
          <b-button-group>
            <b-button :to="`/user/${row.item.id}/edit`" variant="primary" size="sm" v-if="canAdmin(row.item)">
              <unicon name="pen"></unicon>
            </b-button>
            <b-button :to="`/user/${row.item.id}`" variant="primary" size="sm">
              <unicon name="eye"></unicon>
            </b-button>
            <b-button @click="invalidate(row.item)" variant="primary" v-if="row.item.enabled && canAdmin(row.item)" size="sm">Invalider</b-button>
            <b-button @click="validate(row.item)" variant="primary" size="sm" v-if="!row.item.enabled && canAdmin(row.item)">Valider</b-button>
          </b-button-group>
        </template>
        <template v-slot:cell(organisations)="row">
          {{ row.item.organisations.map(o => o.name).join(', ') }}
        </template>
        <template v-slot:cell(contact)="row">
            <b-button @click="() => $emit('contact', row.item.id)" variant="primary" size="sm">Contacter</b-button>
        </template>
        <template #table-busy>
          <div class="text-center" text-primary>
            <b-spinner v-if="loading" class="align-middle"/>
          </div>
        </template>
      </b-table>
      <b-pagination
        pills
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        aria-controls="my-table"
        @input="toPage"/>
    </template>
    <b-alert v-if="!loading && users.length === 0" show>
      <slot name="none">Aucun utilisateur à afficher.</slot>
    </b-alert>
  </div>
</template>
<script>
import User from './model'
import table from '@/views/table.mixin'

export default {
  name: 'gip.user.table',
  mixins: [table(User, 'users')],
  props: {
    fields: {
      type: Array,
      default: () => [
        { key: 'id', label: 'ID' },
        { key: 'lastname', label: 'Nom', sortable: true },
        { key: 'firstname', label: 'Prénom', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'phone', label: 'Téléphone' },
        { key: 'organisations', label: 'Types de structure', sortable: true },
        { key: 'enabled', label: 'Statut', formatter: 'enabledFormatter' },
        { key: 'actions', label: '' }
      ]
    },
    filters: Array
  },
  data () {
    return {
      users: [],
      orders: [{
        column: 'id',
        direction: 'DESC'
      }]
    }
  },
  methods: {
    validate (user) {
      user
        .setData({ enabled: true })
        .save({ with: ['id', 'enabled'] })
    },
    invalidate (user) {
      user
        .setData({ enabled: false })
        .save({ with: ['id', 'enabled'] })
    },
    enabledFormatter (value) {
      return value ? 'Validé' : 'Non validé'
    },
    canAdmin (siret) {
      return this.$user.roles.includes('ROLE_ADMIN')
    }
  }
}
</script>
