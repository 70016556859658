<template>
  <b-button class="btn-popover" v-b-popover.hover.top="title">
    <unicon name="question-circle"></unicon>
  </b-button>
</template>
<script>
export default {
  name: 'gip.tips',
  props: {
    title: String
  }
}
</script>
