import Model from '@/views/model'

class Deadline extends Model {
  constructor (data) {
    super(data)
    if (this.duration) {
      this.duration = Number(this.duration.match(/P0Y(.*)M0DT0H0M0S/)[1])
    }
  }
  static search (options) {
    return super.search(options, Deadline)
  }
  async load () {
    super.load().then(() => {
      this.duration = Number(this.duration.match(/P0Y(.*)M0DT0H0M0S/)[1])
      return this
    })
  }
}

Deadline.prototype.properties = Model.prototype.properties.concat(['label', 'duration'])
Deadline.prototype.baseUrl = '/crud/deadline'

export default Deadline
