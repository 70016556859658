import Model from '@/views/model'
import Category from '@/views/softskill_category/model'

class Softskill extends Model {
  static search (options) {
    return super.search(options, Softskill)
  }
}

Softskill.prototype.properties = Model.prototype.properties.concat(['name', 'categories', 'reviewed'])
Softskill.prototype.baseUrl = '/crud/soft_skill'
Softskill.prototype.schema = { collections: { categories: Category }, includes: {} }

export default Softskill
