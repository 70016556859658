import Model from '@/views/model'
import City from '@/views/city/model'
import Postcode from '@/views/postcode/model'

class CityPostcode extends Model {
  static search (options) {
    return super.search(options, CityPostcode)
  }
}

CityPostcode.prototype.properties = Model.prototype.properties.concat(['city', 'postcode'])
CityPostcode.prototype.baseUrl = '/crud/city_postcode'
CityPostcode.prototype.schema = {
  collections: {},
  includes: {
    city: City,
    postcode: Postcode
  }
}
export default CityPostcode
