<script>
import Trade from '@/views/trade/model'
import Selector from '@/views/selector'

export default {
  name: 'gip.selector.trade',
  extends: Selector,
  props: {
    value: Trade,
    type: {
      default: () => Trade
    }
  },
  data () {
    return {
      serializers: ['default']
    }
  }
}
</script>
