<template>
  <ul class="galaxy_container flex flex-no-wrap " v-if="lowest">
    <li class="galaxy_no-entry" v-if="banable">
      <a v-if="writable" :href="'#'+lowest.label" :title="lowest.label" @click.prevent="obsolete">
        <unicon name="ban" :fill="id !== lowest.id ? '#ccc' : fill" :width="size" :height="size"/>
      </a>
      <unicon v-else name="ban" :fill="id !== lowest.id ? '#ccc' : fill" :width="size" :height="size"/>
    </li>
    <li class="galaxy_star animated bounce" v-for="u in utilities.list" :key="u.id">
      <template  v-if="u.score > 0">
        <a v-if="writable" :href="'#'+u.label" :title="u.label" @click.prevent="() => note(u)">
          <unicon :name="Number(u.score) <= Number(score) ? 'star-full' : 'star'" :fill="id === lowest.id ? '#ccc' : fill" :width="size" :height="size"/>
        </a>
        <unicon v-else :name="Number(u.score) <= Number(score) ? 'star-full' : 'star'" :fill="id === lowest.id ? '#ccc' :fill" :title="u.label" :width="size" :height="size"/>
      </template>
    </li>
  </ul>
</template>
<script>
import model from '@/views/model.mixin'
import Utility from '@/views/utility/model'
import utilities from '@/views/utility/cache'

export default {
  name: 'gip.utility.galaxy',
  props: {
    value: Utility,
    writable: {
      type: Boolean,
      default: false
    },
    fill: {
      type: String,
      default: '#ed6966'
    },
    size: {
      type: String,
      default: '1.4em'
    },
    banable: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  mixins: [model('value')],
  data () {
    return {
      utilities,
      lowest: null
    }
  },
  mounted () {
    utilities.load().then(() => {
      this.lowest = utilities.list[0]
    })
  },
  methods: {
    note (utility) {
      if (this.id === utility.id) {
        utility = this.utilities.list[this.utilities.list.indexOf(utility) - 1]
      }
      this.value.setData(utility ? utility.marshall() : this.utilities.list[1].marshall())
      this.$emit('change')
    },
    obsolete () {
      if (this.lowest.id === this.value.id) {
        this.note(this.utilities.list[1])
      } else {
        this.note(this.lowest)
      }
    }
  }
}
</script>
<style scoped lang="scss">
ul {
  display: flex;
  li {
    list-style-type: none;
  }
  margin: auto;
  padding: 0;
}

.animated {
  animation-duration: 0.2s;
  animation-fill-mode: both;
}

@keyframes in {
  0% {transform: scale(1);}
  60% {transform: scale(1.8);}
  100% {transform: scale(1.4);}
}

.bounce:hover {
  animation-name: in;
}
.bounce {
  transform: scale(1);
}
</style>
