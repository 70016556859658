<template>
  <section>
    <header class="bloc-title">
      <h1 class="bloc-title-content">Conditions de travail</h1>
    </header>
    <b-row>
      <b-col cols="12" xl="6">
        <work-condition-select v-model="workCondition" @input="addWorkCondition" class="mb-2"></work-condition-select>
        <b-badge v-for="wc in workConditions" :key="wc.id" variant="outlined-primary" class="mr-1 mb-1"  style="line-height: 1.1em; font-size: 1em;">
          {{ wc.name }}
          <b-button-close @click="() => removeWorkCondition(wc)" class="ml-1 mr-0" variant="info" style="font-size: 1em; line-height: 1.2em;"/>
        </b-badge>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xl="6">
        <b-form-group label="Commentaire conditions de travail ?" >
          <b-form-textarea v-model="value.workConditionsComment" rows="4" />
        </b-form-group>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import Condition from './model'
import VisitTrade from '@/views/visit_trade/model'
import model from '@/views/model.mixin'
import WorkConditionSelect from './select'

export default {
  name: 'gip.visit.trade.work_condition.form',
  props: {
    value: VisitTrade
  },
  components: { WorkConditionSelect },
  mixins: [model('value')],
  data () {
    return {
      workCondition: new Condition()
    }
  },
  methods: {
    save () {
      const args = {
        with: ['workConditions', 'workConditionsComment'],
        workConditions: {
          fn: all => all.map(s => s.id)
        }
      }
      this.value
        .save(args)
        .then(() => {
          this.$notify({
            content: `Conditions de travail de ${this.value.trade.name} modifiées`,
            title: 'Conditions de travail'
          })
          this.$emit('done', this.value)
        }).catch(error => {
          this.handleError(error)
        })
    },
    onChange (values) {
      this.workConditions.splice(0, this.value.workConditions.length, ...values)
    },
    addWorkCondition (wc) {
      if (this.workConditions.findIndex(w => w.id === wc.id) === -1) {
        this.workConditions.push(wc)
      }
      this.workCondition = new Condition()
    },
    removeWorkCondition (wc) {
      const at = this.workConditions.findIndex(w => w.id === wc.id)
      if (at >= 0) {
        this.workConditions.splice(at, 1)
      }
    }
  }
}
</script>
