<script>
import Skill from '@/views/skill/model'
import Selector from '@/views/selector'
import searchSkill from '@/views/skill/skapane'
import Trade from '@/views/trade/model'
import axios from 'axios'
const CancelToken = axios.CancelToken

export default {
  name: 'gip.skill.selector',
  extends: Selector,
  props: {
    value: Skill,
    type: {
      default: () => Skill
    },
    trade: Trade
  },
  data () {
    return {
      filters: [
        {
          type: '\\App\\Model\\Filter\\EqualsFilter',
          field: 'reviewed',
          value: 'true'
        }
      ],
      serializers: ['default'],
      source: null,
      throttle: null
    }
  },
  methods: {
    async search (val) {
      if (this.throttle) {
        clearTimeout(this.throttle)
        this.throttle = null
        this.searchResolve = null
      }
      if (val && val.length >= this.minLength) {
        this.lastInput = val
        return new Promise((resolve, reject) => {
          this.throttle = setTimeout(() => {
            this.throttle = null
            this.searchNow(val)
              .then(resolve, reject)
          }, 100)
        })
      } else return []
    },
    async searchNow (val) {
      if (this.source && 'cancel' in this.source) {
        this.source.cancel()
      }
      this.source = CancelToken.source()
      try {
        this.loading = Promise.all([
          this.type.search({
            filters: this.baseFilters(val, this.field)
              .concat(this.filters),
            serializers: this.serializers || ['default'],
            cancelToken: this.source.token
          }),
          searchSkill({
            text: val,
            associated_text: this.trade.name
          }, this.$config && this.$config.skapane, this.source.token)
        ])
        const both = await this.loading
        const api = both.pop()
        const entities = both.pop().results
        const done = []
        api.forEach(a => {
          let skill = entities.find(e => a.text_similar === e.name)
          if (skill) {
            entities.splice(entities.indexOf(skill), 1)
          } else {
            skill = new Skill({
              name: a.text_similar
            })
            done.push(Skill.search({
              filters: [{
                type: '\\App\\Model\\Filter\\EqualsFilter',
                field: 'name',
                value: JSON.stringify(skill.name)
              }],
              limit: 1,
              serializers: ['default'],
              cancelToken: this.source.token
            }).then(data => {
              if (data && data.results && data.results.length === 1) {
                skill.setData(data.results[0])
              }
            }))
          }
          entities.unshift(skill)
        }, entities)
        await Promise.all(done)
        this.source = null
        return entities
      } catch (err) {
        if (axios.isCancel(err)) {
          return []
        } else {
          this.handleError(err)
        }
      }
      this.loading = false
      return []
    }
  }
}
</script>
