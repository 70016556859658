import Model from '@/views/model'

class Naf extends Model {
  static search (options) {
    return super.search(options, Naf)
  }
}

Naf.prototype.properties = Model.prototype.properties.concat(['code', 'name', 'short_name'])
Naf.prototype.baseUrl = '/crud/naf732'
Naf.prototype.schema = {
  collections: {},
  includes: {}
}
export default Naf
