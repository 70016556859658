<script>
import Reason from './model'
import SelectorAll from '@/views/selectorAll'

export default {
  name: 'gip.selector.reason',
  extends: SelectorAll,
  props: {
    value: Reason,
    field: {
      default: () => 'label'
    },
    type: {
      default: () => Reason
    },
    defaultOrders: {
      type: Array,
      default: () => {
        return [{
          column: 'label',
          direction: 'ASC'
        }]
      }
    }
  }
}
</script>
