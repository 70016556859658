<template>
  <b-spinner v-if="state.loading" variant="primary"/>
  <form @submit.prevent="validate" v-else>
    <b-row>
      <b-col cols="12" xl="6">
        <b-form-group label="Métier*">
          <trade-select v-model="trade" @input="onSelectTrade" class="mb-3"/>
        </b-form-group>
      </b-col>
      <b-col cols="12" xl="6" class="switch-container-flex">
        <b-form-checkbox v-model="recruitmentNeeded" switch @change="switchRecruitmentNeeded">
          L'entreprise a-t-elle des besoins en recrutement sur ce métier ?
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row :class="[!recruitmentNeeded ? 'd-none' : '' ]">
      <b-col cols="12" xl="6">
        <b-form-group label="Nombre de postes en recrutement">
          <b-input v-model="quantity"/>
        </b-form-group>
      </b-col>
      <b-col cols="12" xl="6">
        <b-form-group label="Échéance des recrutements">
          <deadline-select v-model="deadline"></deadline-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row :class="[!recruitmentNeeded ? 'd-none' : '' ]">
      <b-col cols="12" xl="6" class="switch-container-flex">
        <b-form-checkbox v-model="keyJob" switch>
          Est-ce un métier clé selon l'entreprise ?
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" xl="6">
        <b-form-group>
          <template name="label">
            <template v-if="jobTypeShow">Préciser le type de recrutement</template>
            <a href="#" @click.prevent="jobTypeShow = true" v-else>Préciser le type de recrutement</a>
          </template>
          <template v-if="jobTypeShow">
            <b-form-checkbox v-model="newJob">Création de poste</b-form-checkbox>
            <b-form-checkbox v-model="companyReorganization">Réorganisation de l'entreprise</b-form-checkbox>
            <b-form-checkbox v-model="renewJob">Vacance de poste</b-form-checkbox>
          </template>
        </b-form-group>
      </b-col>
    </b-row>
    <!--<b-row>
      <b-col cols="12" class="switch-container-flex">
        <b-form-checkbox v-model="deprecated" switch @change="switchDeprecated">
          Ce métier est-il amené à disparaître ?
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row :class="[!deprecated ? 'd-none' : '' ]">
      <b-col cols="12" xl="6">
        <b-form-group label="Nombre de postes concernés">
          <b-input v-model="deprecatedQuantity"/>
        </b-form-group>
      </b-col>
      <b-col cols="12" xl="6">
        <b-form-group label="Échéance">
          <deadline-select v-model="deprecationDeadline"></deadline-select>
        </b-form-group>
      </b-col>
    </b-row>-->
    <p class="spacer"></p>
    <b-row>
      <b-col>
        <b-form-group label="L'entreprise a-t-elle indiqué une problématique particulière sur ce métier ?">
          <b-form-textarea v-model="extra" rows="4"></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="text-right">
        <slot name="actions" v-bind:isValid="isValid">
          <b-button type="submit" variant="primary" :disabled="!isValid || state.saving">
            <b-spinner v-if="state.saving" variant="light" type="grow" small/>
            Enregistrer
          </b-button>
        </slot>
      </b-col>
    </b-row>
    <b-modal ref="confirm" title="Éditer le métier" centered>
      <p class="my-4">Les compétences renseignées seront remplacées. Modifier tout de même ?</p>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel">Annuler</b-button>
        <b-button variant="primary" @click="() => save(ok)">Modifier</b-button>
      </template>
    </b-modal>
  </form>
</template>
<script>
import model from '@/views/model.mixin'
import VisitTrade from '@/views/visit_trade/model'
import VisitTradeSkill from '@/views/visit_trade_skill/model'
import TradeSelect from '@/views/trade/select'
import Deadline from '@/views/deadline/model'
import DeadlineSelect from '@/views/deadline/select'
import pick from 'ramda/src/pick'
import utilities from '@/views/utility/cache'
import { identity } from '@/views/fun'

export default {
  name: 'gip.visit.trade.form',
  props: {
    value: VisitTrade
  },
  components: {
    DeadlineSelect,
    TradeSelect
  },
  mixins: [model('value')],
  data () {
    if (this.value.modelState.loading) {
      this.value.modelState.loading.then(() => {
        Object.assign(
          this,
          {
            hasTrade: Boolean(this.value.trade.id),
            initialTradeId: this.value.trade.id
          }
        )
      })
    }
    this.value.on('update', () => {
      if (!this.jobTypeShow) {
        this.jobTypeShow = this.value.companyReorganization || this.value.newJob || this.value.renewJob
      }
    })
    return {
      hasTrade: Boolean(this.value.trade.id),
      initialTradeId: this.value.trade.id,
      jobTypeShow: this.value.companyReorganization || this.value.newJob || this.value.renewJob
    }
  },
  mounted () {
    utilities.load()
  },
  methods: {
    switchRecruitmentNeeded () {
      if (!this.recruitmentNeeded) {
        this.quantity = null
        this.deadline = new Deadline()
        this.companyReorganization = false
        this.newJob = false
        this.renewJob = false
        this.keyJob = false
      }
    },
    /* switchDeprecated () {
      if (!this.deprecated) {
        this.deprecatedQuantity = null
        this.deprecationDeadline = new Deadline()
      }
    }, */
    onSelectTrade (trade) {
      this.hasTrade = true
    },
    onSubmit () {
      this.value.setData(pick(['quantity'], this))
    },
    validate () {
      if (this.id && this.initialTradeId !== this.trade.id) {
        this.$refs.confirm.show()
      } else {
        this.save()
      }
    },
    async save (ok) {
      ok && ok()
      const isCreate = !this.id
      if (this.id && (this.initialTradeId !== this.trade.id)) {
        await this.value.delete()
        this.id = null
      }
      this.value.setData(pick(this.value.properties, this))
      const opts = {
        without: ['id', 'languages', 'softSkills', 'workConditions'],
        trade: identity,
        deadline: identity
        // deprecationDeadline: identity
      }
      if (this.id) {
        opts.without.push('visit')
      } else {
        opts.visit = { fn: v => v.id || v }
      }
      let list
      if (!this.initialTradeId || (this.initialTradeId !== this.trade.id)) {
        list = this.trade.skills
          .map(s => new VisitTradeSkill({
            skill: s,
            utility: utilities.list[1].clone(),
            numerical: false,
            ecological: false,
            technological: false
          }))
        this.skills.splice(0, this.skills.length, ...list)
        opts.childs = {
          skills: {
            without: ['id', 'reviewed'],
            skill: { fn: vs => vs.skill.id },
            utility: identity
          }
        }
      } else {
        opts.without.push('skills')
      }
      this.value.save(opts).then(
        () => {
          if (isCreate) {
            this.$notify({
              content: 'Le métier a été ajouté',
              title: 'Visite mise à jour'
            })
          }
          this.$emit('done', this.value)
        }
      ).catch((error) => {
        this.handleError(error)
      })
    }
  },
  computed: {
    isValid () {
      return this.hasTrade
    }
  }
}
</script>
