<script>
import Organisation from './model'
import SelectorAll from '@/views/selectorAll'

export default {
  name: 'gip.selector.organisation',
  extends: SelectorAll,
  props: {
    value: Organisation,
    type: {
      default: () => Organisation
    },
    defaultOrders: {
      type: Array,
      default: () => {
        return [{
          column: 'name',
          direction: 'ASC'
        }]
      }
    }
  }
}
</script>
