import { handleError, handlePromiseError, onAgreementError, notifyError } from '@/views/error/handlers'

export default {
  methods: {
    notifyError,
    handleError,
    onAgreementError,
    handlePromiseError
  }
}
