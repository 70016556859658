<template>
  <b-form @submit.prevent="onSubmit" v-if="cgu || engagement || rgpd">

    <b-form-group v-if="cgu">
      <b-form-checkbox v-model="cguAccepted" switch required>
        J'accepte les <a href="#/content/cgu" target="_blank">CGU</a>*
      </b-form-checkbox>
    </b-form-group>

    <b-form-group v-if="engagement">
      <b-form-checkbox v-model="engagementAccepted" switch required>
        J'accepte la <a href="#/content/engagement" target="_blank">charte d'engagement</a>*
      </b-form-checkbox>
    </b-form-group>

    <b-form-group v-if="rgpd">
      <b-form-checkbox v-model="rgpdAccepted" switch required>
        J'accepte la charte <a href="#/content/rgpd" target="_blank">RGPD</a>*
      </b-form-checkbox>
    </b-form-group>

    <b-button type="submit" variant="primary" :disabled="!submitable">
      <b-spinner small type="grow" variant="white" v-if="saving"/>
      Enregistrer
    </b-button>

  </b-form>

  <ul v-else>
    <li><b-link to="/content/cgu" target="_blank">CGU</b-link></li>
    <li><b-link to="/content/engagement" target="_blank">charte d'engagement</b-link></li>
    <li><b-link to="/content/rgpd" target="_blank">RGPD</b-link></li>
  </ul>

</template>
<script>
import Model from '@/views/model'
import model from '@/views/model.mixin'
import User from '@/views/user/model'

export default {
  name: 'gip.user.agreement.form',
  props: {
    value: {
      type: User,
      default () {
        return this.$user
      }
    }
  },
  mixins: [model([])],
  data () {
    return {
      saving: false,
      cguAccepted: false,
      engagementAccepted: false,
      rgpdAccepted: false
    }
  },
  computed: {
    submitable () {
      return ['cgu', 'engagement', 'rgpd'].filter(doc => this[doc] === this[doc + 'Accepted']).length === 3
    },
    cgu () {
      return this.$user.needAgreements.includes('cgu')
    },
    engagement () {
      return this.$user.needAgreements.includes('engagement')
    },
    rgpd () {
      return this.$user.needAgreements.includes('rgpd')
    }
  },
  mounted () {
    if (!(this.cgu || this.engagement || this.rgpd)) {
      Model.api
        .then(api => api.get('/'))
        .catch(this.handleError.bind(this))
    }
  },
  methods: {
    onSubmit () {
      this.saving = Promise.all(
        ['cgu', 'engagement', 'rgpd']
          .filter(doc => this[doc] && this[doc + 'Accepted'])
          .map(doc => {
            return Model.api
              .then(api => api.get(`/security/${doc}/accept`))
              .then(() => this.$user.needAgreements.splice(this.$user.needAgreements.indexOf(doc), 1))
          })
      ).then(
        async () => {
          if (this.$user.isAuth) {
            await this.$user.me()
          }
          this.saving = false
          this.$router.push({ name: 'home' })
        }
      ).catch(
        err => {
          this.saving = false
          this.handleError(err)
        }
      )
    }
  }
}
</script>
