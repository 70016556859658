import Model from '@/views/model'
import Utility from '@/views/utility/model'
import Skill from '@/views/skill/model'

const schema = {
  collections: {},
  includes: {
    utility: Utility,
    skill: Skill
  }
}

class VisitTradeSkill extends Model {
  constructor (data) {
    super(data)
    let defaults = {}
    if (this.numerical === null) {
      defaults.numerical = false
    }
    if (this.ecological === null) {
      defaults.ecological = false
    }
    if (this.technological === null) {
      defaults.technological = false
    }
    this.setData(defaults)
  }
  async load () {
    return super.load()
      .then(() => {
        this.setData({
          skill: {},
          utility: {},
          numerical: false,
          ecological: false,
          technological: false
        })
        return Promise.resolve(this)
      })
  }
  static search (options) {
    return super.search(options, VisitTradeSkill)
  }
}

VisitTradeSkill.prototype.properties = Model.prototype.properties.concat([
  'skill',
  'utility',
  'numerical',
  'ecological',
  'technological',
  'reviewed'
])
VisitTradeSkill.prototype.schema = schema
VisitTradeSkill.prototype.baseUrl = '/crud/visit_trade_skill'

export default VisitTradeSkill
