import Model from '@/views/model'

class Region extends Model {
  constructor (data = {}) {
    super(data)
  }
  static search (options) {
    return super.search(options, Region)
  }
}

Region.prototype.properties = Model.prototype.properties.concat(['code', 'name', 'lat', 'lng'])
Region.prototype.baseUrl = '/crud/region'

export default Region
