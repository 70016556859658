import Model from '@/views/model'
import CityPostcode from '@/views/city_postcode/model'
import Naf from '@/views/naf/model'

class Siret extends Model {
  static search (options) {
    return super.search(options, Siret)
  }
}

Siret.prototype.properties = Model.prototype.properties.concat(['siret', 'name', 'cityPostcode', 'address', 'workforce', 'datemaj', 'naf'])
Siret.prototype.baseUrl = '/crud/siret'
Siret.prototype.schema = {
  collections: {},
  includes: {
    cityPostcode: CityPostcode,
    naf: Naf
  }
}

export default Siret
