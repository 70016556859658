import Model from '@/views/model'

class Condition extends Model {
  static search (options) {
    return super.search(options, Condition)
  }
}

Condition.prototype.schema = {
  collections: {},
  includes: {}
}
Condition.prototype.properties = Model.prototype.properties.concat(['name'])
Condition.prototype.baseUrl = '/crud/work_condition'

export default Condition
