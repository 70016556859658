import Model from '@/views/model'
import PostCode from '@/views/postcode/model'
import EmploymentZone from '@/views/employment_zone/model'
import Region from '@/views/region/model'
import Department from '@/views/department/model'

class City extends Model {
  constructor (data = {}) {
    super(data)
  }
  static search (options) {
    return super.search(options, City)
  }
}

City.prototype.properties = Model.prototype.properties.concat(['code', 'name', 'lat', 'lng', 'region', 'department', 'postcodes', 'employmentZone'])
City.prototype.baseUrl = '/crud/city'
City.prototype.schema = {
  collections: {
    postcodes: PostCode
  },
  includes: {
    employmentZone: EmploymentZone,
    region: Region,
    department: Department
  }
}

export default City
