<script>
import Mission from './model'
import SelectorAll from '@/views/selectorAll'

export default {
  name: 'gip.mission.select',
  extends: SelectorAll,
  props: {
    value: Mission,
    type: {
      default: () => Mission
    },
    defaultOrders: {
      type: Array,
      default: () => {
        return [{
          column: 'name',
          direction: 'ASC'
        }]
      }
    }
  }
}
</script>
