import Model from '@/views/model'
import debug from 'debug'
import Reason from '@/views/reason/model'
import Organisation from '@/views/organisation/model'
import Avatar from '@/views/attachment/avatar'
import CityPostcode from '@/views/city_postcode/model'
import { required, checkValue, checkPattern } from '@/views/validation/validator'
import Mission from '@/views/mission/model'

const log = debug('app:model')

class User extends Model {
  constructor (data) {
    super(data)
    this.setState('logging', false)
    this.isAuth = false
    this.needAgreements = []
  }
  static async searchResultsExportExcel (search) {
    const data = {}
    if (search.email) {
      data.email = search.email
    }
    if (search.lastname) {
      data.lastname = search.lastname
    }
    if (typeof search.enabled !== 'undefined' && search.enabled !== null) {
      data.enabled = search.enabled
    }
    if (search.organisation) {
      data.organisation = search.organisation
    }
    const response = await Model.api.then(api => api.get('/export/user', { params: data }))
    return response
  }
  static search (options) {
    return super.search(options, User)
  }
  async login ({ email, password }) {
    let req
    try {
      this.setState('logging', true)
      req = Model.api.then(api => api.post('/security/login', { email, password }))
      const { data } = await req
      const u = data.user
      delete data.user
      await this.auth(data)
      this.setData(u)
      req = this.me()
      await req
      this.setState('logging', false)
      return data
    } catch (err) {
      log(err)
      this.setState('logging', false)
      throw req
    }
  }
  async passwordRequest ({ email }) {
    let req
    try {
      this.setState('logging', true)
      req = Model.api.then(api => api.post('/security/forgot-password', { email }))
      const { data } = await req
      this.setState('logging', false)
      return data
    } catch (err) {
      log(err)
      this.setState('logging', false)
      throw req
    }
  }
  async contact ({ email, firstname, lastname, message }) {
    let req
    try {
      this.setState('logging', true)
      req = Model.api.then(api => api.post('/contact-request', { email, firstname, lastname, message }))
      const { data } = await req
      this.setState('logging', false)
      return data
    } catch (err) {
      log(err)
      this.setState('logging', false)
      throw req
    }
  }
  logout () {
    return this.auth(null)
  }
  async me () {
    if (this.isAuth) {
      let req
      try {
        this.setState('loading', true)
        let api = await Model.api
        req = api.get('/me')
        const { data } = await req
        this.setData(data)
        this.setState('loading', false)
        return this
      } catch (err) {
        log(err)
        this.setState('loading', false)
        throw req
      }
    }
  }
  async auth (data) {
    if (typeof data === 'undefined') {
      const item = window.localStorage.getItem('session')
      if (item) {
        const auth = JSON.parse(item)
        if (auth) {
          return this.auth(auth)
        }
      }
    }
    if (data === null) {
      Model.api.then(api => {
        api.defaults.headers.common['x-auth-token'] = null
      })
      window.localStorage.removeItem('session')
      this.isAuth = false
      this.emit('update', this)
      return this
    }
    if (data && data.token) {
      this.authData = data
      Model.api.then(api => {
        api.defaults.headers.common['x-auth-token'] = data.token
      })
      window.localStorage.setItem('session', JSON.stringify(data))
      this.isAuth = true
      this.emit('update', this)
      return this
    }
  }
  async credential (password) {
    const { data } = await Model.api
      .then(api => {
        return api.patch([this.baseUrl, this.id].join('/'), {
          plainPassword: password
        }, {
          headers: {
            'x-validation-groups': 'password'
          }
        })
      })
    return data
  }
  onAgreementError (err) {
    if (err && err.response && 'x-need-user-agreement' in err.response.headers) {
      this.needAgreements.splice(
        0,
        this.needAgreements.length,
        ...err.response.headers['x-need-user-agreement'].split(',').map(a => a.trim())
      )
      return true
    }
  }
}

User.prototype.baseUrl = '/crud/user'
User.prototype.schema = {
  collections: {
    reasons: Reason,
    organisations: Organisation
  },
  includes: {
    avatar: Avatar,
    cityPostcode: CityPostcode,
    mission: Mission
  }
}
User.prototype.properties = Model.prototype.properties.concat([
  'email',
  'firstname',
  'lastname',
  'gender',
  'address',
  'cityPostcode',
  'phone',
  'organisations',
  'reasons',
  'mission',
  'structure',
  'enabled',
  'avatar',
  'cguAccepted',
  'rgpdAccepted',
  'engagementAccepted',
  'roles',
  'companyVisit',
  'regionalActor'
])

User.prototype.validator = {
  validate (value) {
    const requiredFields = [
      'email',
      'lastname',
      'firstname',
      'address',
      'cityPostcode',
      'phone',
      'organisations',
      'gender',
      'reasons',
      'structure',
      'mission'
    ]
    let message = null
    message = required(requiredFields, value)
    if (!message) {
      const civilityValues = ['male', 'female']
      message = checkValue(civilityValues, value.gender)
      if (!message) {
        message = checkPattern(value.phone, true, /^[0-9]+$/, 9)
        if (!message) {
          message = checkPattern(value.email, true, /\S+@\S+\.\S+/)
        }
      }
    }
    if (message) {
      return { error: message }
    }
    return 'valid'
  }
}

export default User
