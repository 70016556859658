<template>
  <b-spinner v-if="state.loading" variant="primary"/>
  <b-form v-else @submit.prevent="save">
    <b-card class="visit-trade-skills_title">
      <span class="visit-trade-skills_media">
        <unicon name="suitcase" fill="white"/>
        <span>Métier</span>
      </span>
      <h3>{{ trade.name }}</h3>
    </b-card>

    <header class="bloc-title">
      <h1 class="bloc-title-content">Compétences ajoutées</h1>
    </header>
    <skill-table :skills="pimpeds" :columns="baseFields">
      <template v-slot:ecological="{skill}">
        <b-form-checkbox v-model="skill.ecological" switch  size="lg"/>
      </template>
      <template v-slot:numerical="{skill}">
        <b-form-checkbox v-model="skill.numerical" switch size="lg"/>
      </template>
      <template v-slot:technological="{skill}">
        <b-form-checkbox v-model="skill.technological" switch size="lg"/>
      </template>
    </skill-table>

    <header class="bloc-title">
      <h1 class="bloc-title-content">Compétences</h1>
    </header>
    <skill-table :skills="specifics" :columns="baseFields">
      <template v-slot:ecological="{skill}">
        <b-form-checkbox v-model="skill.ecological" switch size="lg"/>
      </template>
      <template v-slot:numerical="{skill}">
        <b-form-checkbox v-model="skill.numerical" switch size="lg"/>
      </template>
      <template v-slot:technological="{skill}">
        <b-form-checkbox v-model="skill.technological" switch size="lg"/>
      </template>
    </skill-table>

    <slot name="actions">
      <b-button type="submit" variant="primary" class="float-right">
        <b-spinner v-if="state.saving" variant="light" type="grow" small/>
        Enregistrer
      </b-button>
    </slot>

  </b-form>
</template>
<script>
import SkillTable from '@/views/visit_trade_skill/table'
import VisitTrade from '@/views/visit_trade/model'
import model from '@/views/model.mixin'
import { identity } from '@/views/fun'

export default {
  name: 'gip.visit.trade.skills.special.form',
  props: {
    value: VisitTrade
  },
  components: { SkillTable },
  mixins: [model('value')],
  data () {
    return {
      baseFields: [
        { key: 'skill.name', label: 'Compétences' },
        { key: 'numerical' },
        { key: 'ecological' },
        { key: 'technological' }
      ]
    }
  },
  computed: {
    specifics () {
      return this.skills.filter(vts => this.trade.skills.find(s => vts.skill.id === s.skill.id && vts.utility.score > 0))
    },
    pimpeds () {
      return this.skills.filter(vts => !this.trade.skills.find(s => vts.skill.id === s.skill.id))
    }
  },
  methods: {
    save () {
      const args = {
        with: ['skills'],
        skills: {
          fn: all => all
            .map(s => {
              return s.marshall({
                without: ['utility'],
                skill: identity,
                with: ['ecological', 'numerical', 'id', 'technological']
              })
            })
        }
      }
      this.value
        .save(args)
        .then(
          () => {
            this.$notify({
              content: `Compétences de ${this.value.trade.name} modifiées`,
              title: 'Compétences métier'
            })
            this.$emit('done', this.value)
          }
        )
    }
  }
}
</script>
<style lang="scss">
  @import '@/assets/custom-vars.scss';
  .visit-trade-skills_title {
    max-width: 50%;
    margin: 0 auto;
    .card-body {
      display: flex;
      align-items: center;
    }
    .unicon {
      margin: 0 1em;
    }
    .visit-trade-skills_media {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
    }
    h3 {
      display: inline-block;
      background: #ffffff77;
      border-radius: 10px;
      padding: 0.2em 0.4em;
      margin: 0;
    }
  }
  @media screen and (max-width: 1200px) {
    .visit-trade-skills_title {
      max-width: 100%;
    }
  }
</style>
