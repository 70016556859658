<template>
  <b-form @submit.prevent="onSubmit">
    <b-form-group label="Adresse email*">
      <b-input v-model="email" type="email" required placeholder="john@doe.com"/>
    </b-form-group>
    <b-form-group>
      <b-form-radio-group
        v-model="gender"
        :options="[{ text: 'Madame*', value: 'female' }, { text: 'Monsieur*', value: 'male' }]"
        buttons
        name="radios-btn-warning"
        button-variant="outline-primary"
      ></b-form-radio-group>
    </b-form-group>
    <b-form-row>
      <b-col>
        <b-form-group label="Nom*">
          <b-input v-model="lastname" required placeholder="Doe"/>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Prénom*">
          <b-input v-model="firstname" required placeholder="John"/>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-group label="Nom de la structure*">
      <b-input v-model="structure" required/>
    </b-form-group>
    <b-form-group label="Adresse complète*">
      <b-form-textarea v-model="address" required placeholder="Adresse complète" rows="4"></b-form-textarea>
    </b-form-group>
    <b-form-group>
      <template v-slot:label>
        Code postal / Ville*
        <tips title="Choisissez bien votre code postal / ville dans la liste déroulante"/>
      </template>
      <city-postcode-select v-model="cityPostcode" required />
    </b-form-group>
    <b-form-group label="Numéro de téléphone*">
      <b-input v-model="phone" placeholder="0123456789" required/>
    </b-form-group>
    <b-form-group label="Types de structure*">
      <organisation-select v-model="selectOrganisation" @input="addOrganisation"></organisation-select>
      <b-badge v-for="o in organisations" :key="o.id" variant="outlined-primary" class="mr-1 mb-1"  style="line-height: 1.1em; font-size: 1em;">
        {{ o.name }}
        <b-button-close @click="() => removeOrganisation(o)" class="ml-1 mr-0" variant="info" style="font-size: 1em; line-height: 1.2em;"/>
      </b-badge>
    </b-form-group>
    <b-form-group label="Raisons de l'inscription*">
      <reason-select v-model="selectReason" @input="addReason"></reason-select>
      <b-badge v-for="r in reasons" :key="r.id" variant="outlined-primary" class="mr-1 mb-1"  style="line-height: 1.1em; font-size: 1em;">
        {{ r.label }}
        <b-button-close @click="() => removeReason(r)" class="ml-1 mr-0" variant="info" style="font-size: 1em; line-height: 1.2em;"/>
      </b-badge>
    </b-form-group>
    <b-form-group label="Champ d'intervention*">
      <mission-select v-model="mission" required></mission-select>
    </b-form-group>
    <b-form-group label="Avatar ou photo de profil (jpg, jpeg ou png)" v-if="id">
      <b-row>
        <b-col>
          <b-form-file placeholder="Choisissez un fichier" v-model="upload"></b-form-file>
        </b-col>
        <b-col>
          <b-progress
            v-if="(avatar.modelState.progress !== 0 || !avatar.id) && !avatar.preview"
            :value="avatar.modelState.progress">
          </b-progress>
          <avatar v-else v-model="avatar" width="100px"/>
        </b-col>
      </b-row>
    </b-form-group>
    <b-form-group>
      <b-form-checkbox v-model="companyVisit" switch>
        Visite d'entreprise
      </b-form-checkbox>
    </b-form-group>
    <b-form-group>
      <b-form-checkbox v-model="regionalActor" switch>
        Acteur régional
      </b-form-checkbox>
    </b-form-group>
    <b-form-group v-if="!id">
      <b-form-checkbox v-model="cguAccepted" switch required>
        J'accepte les <b-link to="/content/cgu" target="_blank">CGU</b-link>*
      </b-form-checkbox>
    </b-form-group>
    <b-form-group v-if="!id">
      <b-form-checkbox v-model="engagementAccepted" switch required>
        J'accepte la <b-link to="/content/engagement" target="_blank">charte d'engagement</b-link>*
      </b-form-checkbox>
    </b-form-group>
    <b-form-group v-if="!id">
      <b-form-checkbox v-model="rgpdAccepted" switch required>
        J'accepte la charte <b-link to="/content/rgpd" target="_blank">RGPD</b-link>*
      </b-form-checkbox>
    </b-form-group>
    <slot name="actions">
      <b-button type="submit" variant="primary" :disabled="!isValid || !cguAccepted || !engagementAccepted || !rgpdAccepted" v-if="!id">S'inscrire</b-button>
      <b-button type="submit" variant="primary" v-else :disabled="!isValid">Enregistrer</b-button>
      <b-spinner small type="grow" variant="primary" v-if="state.loading || state.saving"></b-spinner>
    </slot>
  </b-form>
</template>
<script>
import User from './model'
import Reason from '../reason/model'
import Organisation from '../organisation/model'
import model from '@/views/model.mixin'
import pick from 'ramda/src/pick'
import ReasonSelect from '@/views/reason/select'
import OrganisationSelect from '@/views/organisation/select'
import MissionSelect from '@/views/mission/select'
import Avatar from '@/views/attachment/avatar'
import AvatarVue from '@/views/attachment/avatar.vue'
import Mime from 'mime'
import CityPostcodeSelect from '@/views/city_postcode/select'
import Tips from '@/views/tips'

export default {
  name: 'gip.user.form',
  props: {
    value: User
  },
  mixins: [model('value')],
  data () {
    return {
      upload: null,
      selectReason: new Reason(),
      selectOrganisation: new Organisation()
    }
  },
  components: {
    ReasonSelect,
    Avatar: AvatarVue,
    OrganisationSelect,
    CityPostcodeSelect,
    MissionSelect,
    Tips
  },
  mounted () {
    this.avatar.on('update', () => this.$forceUpdate())
  },
  watch: {
    upload (file) {
      if (['jpg', 'jpeg', 'png'].includes(Mime.getExtension(file.type))) {
        this.avatar = new Avatar({
          file,
          name: file.name
        })
        this.avatar.on('update', () => this.$forceUpdate())
        this.avatar.save()
          .then(
            async () => {
              this.avatar.blobToB64()
            },
            () => {
              this.avatar = new Avatar()
              this.warnWrongFileType(file.name)
            }
          )
      } else {
        this.warnWrongFileType(file.name)
      }
    }
  },
  methods: {
    onSubmit () {
      this.value.setData(pick(this.value.properties, this))
      this.$emit('submit', this.value)
    },
    reset () {
      this.upload = null
      this.selectReason = new Reason()
      this.selectOrganisation = new Organisation()
    },
    warnWrongFileType (filename) {
      this.$bvToast.toast(`Ce format de fichier n'est pas autorisé`, {
        title: filename,
        variant: 'danger',
        solid: true
      })
    },
    addReason (newR) {
      if (this.reasons.findIndex(r => newR.id === r.id) === -1) {
        this.reasons.push(newR)
      }
      this.selectReason = new Reason()
    },
    removeReason (oldR) {
      const at = this.reasons.findIndex(r => r.id === oldR.id)
      if (at >= 0) {
        this.reasons.splice(at, 1)
      }
    },
    addOrganisation (newO) {
      if (this.organisations.findIndex(o => newO.id === o.id) === -1) {
        this.organisations.push(newO)
      }
      this.selectOrganisation = new Organisation()
    },
    removeOrganisation (oldO) {
      const at = this.organisations.findIndex(o => o.id === oldO.id)
      if (at >= 0) {
        this.organisations.splice(at, 1)
      }
    }
  }
}
</script>
<style>
.form-group button {
  margin-right: 5px;
}
</style>
