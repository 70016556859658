<script>
import Condition from './model'
import SelectorAll from '@/views/selectorAll'

export default {
  name: 'gip.workCondition.select',
  extends: SelectorAll,
  props: {
    value: Condition,
    field: {
      default: () => 'name'
    },
    type: {
      default: () => Condition
    },
    defaultOrders: {
      type: Array,
      default: () => {
        return [{
          column: 'name',
          direction: 'ASC'
        }]
      }
    }
  }
}
</script>
