<template>
  <b-list-group>
    <b-spinner v-if="loading" variant="primary" type="grow" small class="m-auto"/>
    <template v-else v-for="softskill in softskills" >
      <b-list-group-item v-if="!isDisable(softskill)" :key="softskill.id" @click="$emit('select', softskill)" href="#">
        {{ softskill.name + categoriesText(softskill) }}
        <unicon name="plus-circle" fill="#ed6966" class="float-right"></unicon>
      </b-list-group-item>
    </template>
  </b-list-group>
</template>
<style scoped lang="scss">
@import '@/assets/custom-vars.scss';
.list-group {
  .list-group-item {
    border: none;
    color: $color-blue-dark;
    padding: 0.3em 0.8em 0.1em 0.8em;
    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &:last-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:nth-child(odd) {
      background: $color-blue-light;
    }
    &:hover {
      background: $color-blue-light-new;
    }
  }
}
</style>
<script>
import Category from '@/views/softskill_category/model'
import { sortBy } from '@/views/fun'

function collectIds (category) {
  return category.children.reduce((acc, c) => acc.concat(collectIds(c)), []).concat([category.id])
}

export default {
  name: 'gip.softskill.category.skillsoft.list',
  props: {
    categories: Array,
    deny: Array
  },
  data () {
    return {
      softskills: [],
      loading: false,
      initialDeny: this.deny
    }
  },
  mount () {
    this.search()
  },
  watch: {
    categories () {
      this.search()
    }
  },
  methods: {
    async search () {
      this.loading = true
      try {
        const selected = this.categories.reduce((acc, c) => acc.concat(collectIds(c)), [])
        const { results } = await Category.search({ limit: 100 })
        let skills = Category.deduplicate(results)
          .filter(c => selected.includes(c.id))
          .reduce((acc, c) => acc.concat(c.skills), [])
          .filter((skill, i, list) => list.findIndex(s => s.id === skill.id) === i) // dedup
          .filter(skill => skill.reviewed)
        skills.sort(sortBy('name', -1))
        this.softskills.splice(0, this.softskills.length, ...skills)
      } catch {}
      this.loading = false
    },
    isDisable (softskill) {
      return this.initialDeny.some(d => d.softSkill.id === softskill.id)
    },
    getCategorie (id) {
      let c = this.categories.find(c => c.id === id)
      if (!c) {
        let p = this.categories.find(p => p.children.some(c => c.id === id))
        if (p) {
          return p.children.find(c => c.id === id)
        }
      }
      return c
    },
    categoriesText (s) {
      const categories = s.categories.map(c => {
        let txt = (c && typeof c === 'object' && 'name' in c ? c.name : this.getCategorie(c).name)
        return txt.length > 10 ? txt.substring(0, 9) + '…' : txt
      }).join(', ')
      return categories.length ? ` (${categories})` : ''
    }
  }
}
</script>
