import Model from '@/views/model'

class Notification extends Model {
  static search (options) {
    return super.search(options, Notification)
  }
}

Notification.prototype.properties = Model.prototype.properties.concat([
  'createdAt',
  'archived',
  'title',
  'message'
])
Notification.prototype.baseUrl = '/crud/notification'

export default Notification
