<template>
  <b-table responsive striped :items="languages" :fields="fields" :busy="Boolean(busy)">
    <template v-slot:cell(utility)="row">
      <slot name="utility" :language="row.item">
        <galaxy v-model="row.item.utility"/>
      </slot>
    </template>
    <template v-slot:table-busy>
      <slot name="table-busy"></slot>
    </template>
  </b-table>
</template>
<script>
import Galaxy from '@/views/utility/galaxy'

export default {
  name: 'gip.visit.trade.language.list',
  components: { Galaxy },
  props: {
    languages: Array,
    columns: {
      type: Array,
      defaults () {
        return [
          { key: 'language.name' },
          { key: 'utility.score' }
        ]
      }
    },
    busy: [Boolean, Promise]
  },
  data () {
    const defaults = [
      { key: 'language.name', label: 'Langue' },
      { key: 'utility', label: 'Score' }
    ]
    return {
      fields: defaults
        .filter(d => this.columns.find(c => c.key === d.key))
        .map(d => Object.assign({}, d, this.columns.find(c => c.key === d.key)))
    }
  }
}
</script>
