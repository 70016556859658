export default {
  install: function (Vue, options) {
    Vue.prototype.$notify = function notify (notifyOptions) {
      notifyOptions = Object.assign({ title: '', content: '', variant: 'success', toaster: 'b-toaster-top-right', autoHideDelay: 500 }, notifyOptions)
      this.$root.$bvToast.toast(
        notifyOptions.content,
        {
          title: notifyOptions.title,
          solid: true,
          variant: notifyOptions.variant,
          toaster: notifyOptions.toaster,
          autoHideDelay: notifyOptions.autoHideDelay
        }
      )
    }
  }
}
