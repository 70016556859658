import Model from '@/views/model'

class Language extends Model {
  static search (options) {
    return super.search(options, Language)
  }
}

Language.prototype.properties = Model.prototype.properties.concat(['name'])
Language.prototype.baseUrl = '/crud/language'
Language.prototype.schema = {
  collections: {},
  includes: {}
}

export default Language
