<template>
  <b-table responsive striped :items="skills" :fields="fields" sort-by="skill.name" sort-icon-left :sort-compare="sortCompare" :tbody-transition-props="transProps" primary-key="skill.id">

    <template v-slot:cell(actions)="row">
      <slot name="actions" :skill="row.item"></slot>
    </template>

    <template v-slot:cell(utility)="row">
      <slot name="utility" :skill="row.item">
        <galaxy v-model="row.item.utility"/>
        <unicon name="trash" @click="removeSkill(row.item)" />
      </slot>
    </template>

    <template v-slot:head(numerical)="data">
      {{ data.label }}<tips title="Indique que les évolutions du numérique vont impacter la compétence"/>
    </template>
    <template v-slot:cell(numerical)="row">
      <slot name="numerical" :skill="row.item"></slot>
    </template>

    <template v-slot:head(ecological)="data">
      {{ data.label }}<tips title="Indique que l’impact écologique va faire évoluer la compétence"/>
    </template>
    <template v-slot:cell(ecological)="row">
      <slot name="ecological" :skill="row.item"></slot>
    </template>

    <template v-slot:head(technological)="data">
      {{ data.label }}<tips title="Indique que l’impact technologique va faire évoluer la compétence"/>
    </template>
    <template v-slot:cell(technological)="row">
      <slot name="technological" :skill="row.item"></slot>
    </template>

  </b-table>
</template>
<script>
import Galaxy from '@/views/utility/galaxy'
import Tips from '@/views/tips'

export default {
  name: 'gip.visit.trade.skill.table',
  components: { Galaxy, Tips },
  props: {
    skills: Array,
    columns: {
      type: Array,
      defaults () {
        return [
          { key: 'skill.name' },
          { key: 'utility.label' },
          { key: 'numerical' },
          { key: 'ecological' },
          { key: 'technological' }
        ]
      }
    }
  },
  data () {
    const defaults = [
      { key: 'skill.name', label: 'Compétence', sortable: true },
      { key: 'utility', label: 'Score', sortable: true },
      { key: 'numerical', label: 'Impact numérique' },
      { key: 'ecological', label: 'Impact écologique' },
      { key: 'technological', label: 'Impact technologique' },
      { key: 'actions', label: '' }
    ]
    return {
      fields: defaults
        .filter(d => this.columns.find(c => c.key === d.key))
        .map(d => Object.assign({}, d, this.columns.find(c => c.key === d.key))),
      transProps: {
        name: 'flip-list'
      }
    }
  },
  methods: {
    removeSkill (skill) {
      skill.utility = { score: 0 }
    },
    booleanFormatter (value) {
      return value ? 'Oui' : 'Non'
    },
    sortCompare (a, b, key, sortDesc) {
      if (key === 'utility') {
        return Number(b.utility.score) - Number(a.utility.score)
      }
    }
  }
}
</script>
<style>
.flip-list-move {
  transition: transform 0.4s;
}
</style>
