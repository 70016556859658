import Model from '@/views/model'
import Utility from '@/views/utility/model'
import Language from '@/views/language/model'

const schema = {
  collections: {},
  includes: {
    utility: Utility,
    language: Language
  }
}

class VisitTradeLanguage extends Model {
  async load () {
    return super.load()
      .then(() => {
        this.setData({
          language: {},
          utility: {}
        })
        return Promise.resolve(this)
      })
  }
  static search (options) {
    return super.search(options, VisitTradeLanguage)
  }
}

VisitTradeLanguage.prototype.properties = Model.prototype.properties.concat([
  'language',
  'utility'
])
VisitTradeLanguage.prototype.schema = schema

export default VisitTradeLanguage
