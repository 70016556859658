import Model from '@/views/model'

class Utility extends Model {
  static search (options) {
    return super.search(options, Utility)
  }
  clone () {
    return new Utility(this.marshall())
  }
}

Utility.prototype.properties = Model.prototype.properties.concat(['score', 'label'])
Utility.prototype.baseUrl = '/crud/utility'

export default Utility
