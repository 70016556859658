<template>
  <section>

    <header class="bloc-title">
      <h1 class="bloc-title-content">Softskills</h1>
    </header>

     <soft-skill-table :softSkills="softSkills" :columns="softSkillsFields" :busy="state.loading">
      <template v-slot:utility="{softSkill}">
        <galaxy v-model="softSkill.utility" writable :key="$uniq(softSkill)"/>
      </template>
      <template v-slot:table-busy>
        <div class="text-center">
          <b-spinner variant="primary"/>
        </div>
      </template>
    </soft-skill-table>

   <visit-trade-soft-skill-form
      v-model="softSkill"
      ref="softskill"
      @change="() => isAddSoftSkillUnable = canAddSoftSkill()"
      @text="() => isAddSoftSkillUnable = canAddSoftSkill()"
      :deny="softSkills"
      class="mt-5">
      <template v-slot:actions>
        <div>
          <b-button variant="primary" @click="onAddSoftSkill" :disabled="!isAddSoftSkillUnable">
            <unicon name="plus"/>
          </b-button>
          <b-modal ref="confirm" title="Ajouter le soft skill" centered>
            <p class="my-4">Ce soft skill n'existe pas dans notre référentiel, souhaitez vous l'ajouter ?</p>
            <template #modal-footer="{ ok, cancel }">
              <b-button @click="cancel">Annuler</b-button>
              <b-button variant="primary" @click="() => addSoftSkill(ok)" :disabled="isAddingSoftSkill">Ajouter</b-button>
            </template>
          </b-modal>
        </div>
      </template>
    </visit-trade-soft-skill-form>

  </section>
</template>
<script>
import VisitTrade from '@/views/visit_trade/model'
import SoftSkillTable from '@/views/visit_trade_softskill/table'
import SoftSkill from '@/views/softskill/model'
import VisitTradeSoftSkill from '@/views/visit_trade_softskill/model'
import model from '@/views/model.mixin'
import VisitTradeSoftSkillForm from '@/views/visit_trade_softskill/form'
import Galaxy from '@/views/utility/galaxy'

export default {
  name: 'gip.visit.trade.softskills.form',
  props: {
    value: VisitTrade
  },
  components: { SoftSkillTable, Galaxy, VisitTradeSoftSkillForm },
  mixins: [model('value')],
  data () {
    const softSkill = new VisitTradeSoftSkill({ utility: { score: 0 } })
    softSkill.softSkill.on('update', () => {
      this.isAddSoftSkillUnable = this.canAddSoftSkill()
    })
    return {
      softSkillsFields: [
        { key: 'softSkill.name' },
        { key: 'utility' }
      ],
      softSkill,
      isAddSoftSkillUnable: false,
      isAddingSoftSkill: false
    }
  },
  methods: {
    onAddSoftSkill () {
      let name = this.$refs.softskill.$refs.softskill.lastInput
      if (name && name.length === 0) { // softskill from facet search
        name = this.softSkill.softSkill.name
      }
      this.$refs.softskill.onSubmit()
      if (typeof this.softSkill.softSkill.id === 'number' && (name.length === 0 || name === this.softSkill.softSkill.name)) {
        this.addSoftSkill()
      } else if (name.length > 2) {
        this.softSkill.setData({ softSkill: new SoftSkill({ name }) })
        this.$refs.confirm.show()
      }
    },
    async addSoftSkill (ok) {
      if (this.canAddSoftSkill()) {
        let s = this.softSkill.softSkill
        if (!s.id) {
          this.isAddingSoftSkill = true
          await s.save({ without: ['reviewed'] })
          this.isAddingSoftSkill = false
          ok()
          this.$bvToast.toast(`Le soft skill '${s.name}' est ajouté au référentiel.`, {
            title: `Nouveau soft skill`,
            solid: true,
            variant: 'success'
          })
        }
        this.softSkill.removeListener()
        this.softSkills.push(new VisitTradeSoftSkill(this.softSkill.marshall()))
        const softSkill = new VisitTradeSoftSkill({ utility: { score: 0 } })
        softSkill.softSkill.on('update', () => {
          this.isAddSoftSkillUnable = this.canAddSoftSkill()
        })
        this.softSkill = softSkill
        this.isAddSoftSkillUnable = this.canAddSoftSkill()
        this.$refs.softskill.reset()
      }
    },
    canAddSoftSkill () {
      const hasId = typeof this.softSkill.softSkill.id === 'number'
      const noted = this.softSkill.utility.score > 0
      const alreadyAdded = hasId && this.softSkills.some(s => s.softSkill.id === this.softSkill.softSkill.id)
      const name = this.$refs.softskill.$refs.softskill.lastInput
      let isNew = hasId ? (name.length > 1 && name !== this.softSkill.softSkill.name) : this.$refs.softskill.$refs.softskill.lastInput.length > 2
      return (hasId || isNew) && noted && (isNew || !alreadyAdded)
    },
    save () {
      const args = {
        with: ['softSkills'],
        softSkills: {
          fn: all => all
            .filter(s => Number(s.utility.score) > 0)
            .map(s => {
              const args = {
                utility: { fn: u => u.id },
                softSkill: { fn: s => s.id }
              }
              if ('id' in s && s.id !== null) {
                args.with = ['id']
              } else {
                args.without = ['id']
              }
              return s.marshall(args)
            })
        }
      }
      this.value
        .save(args)
        .then(() => {
          this.$notify({
            content: `softskills de ${this.value.trade.name} modifiées`,
            title: 'Softskills'
          })
          this.$emit('done', this.value)
        }).catch(error => {
          this.handleError(error)
        })
    }
  }
}
</script>
<style lang="scss">
  @import '@/assets/custom-vars.scss';
  .visit-trade-skills_title {
    max-width: 50%;
    margin: 0 auto;
    .card-body {
      display: flex;
      align-items: center;
    }
    .unicon {
      margin: 0 1em;
    }
    .visit-trade-skills_media {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
    }
    h3 {
      display: inline-block;
      background: #ffffff77;
      border-radius: 10px;
      padding: 0.2em 0.4em;
      margin: 0;
    }
  }
  @media screen and (max-width: 1200px) {
    .visit-trade-skills_title {
      max-width: 100%;
    }
  }
</style>
