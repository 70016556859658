import Model from '@/views/model'

class Reason extends Model {
  static search (options) {
    return super.search(options, Reason)
  }
}

Reason.prototype.properties = Model.prototype.properties.concat(['label'])
Reason.prototype.baseUrl = '/crud/reason'

export default Reason
